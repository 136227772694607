import type { HasChildren } from '@meterup/atto';
import { expectDefinedOrThrow, HTTPStatusCode, NoConnectionError } from '@meterup/common';
import { useQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import React, { createContext, useContext } from 'react';

import type { IdentityData } from '../api/types';
import { getIdentity } from '../api/api';
import { UnauthenticatedError } from '../errors';
import { COOKIE_NAME } from '../utils/cookies';
import { redirectToSignInPage } from '../utils/redirectToSignInPage';

async function fetchIdentityOrRedirect(returnPath: string): Promise<IdentityData> {
  // NOTE: We're merely checking if the cookie is present to constitute a
  // "session". If it is not set, portal will set it.

  // If the proxy is enabled in vite's dev server
  // checking cookies in the browser is irrelevant, as it is overwritten in the proxy.
  // See dashboard's README for more details
  if (
    !document.cookie.includes(COOKIE_NAME) &&
    import.meta.env?.VITE_PROXY !== 'true' &&
    import.meta.env?.PROXY_COOKIE_OVERRIDE !== 'true'
  ) {
    redirectToSignInPage(returnPath);
  }

  try {
    const identity = await getIdentity();
    if (identity && identity?.permissions?.includes('dashboard')) {
      return identity;
    }

    redirectToSignInPage(returnPath);
    return undefined as any; // Unreached, redirect above, for types
  } catch (e) {
    if (isAxiosError(e)) {
      switch (e.status) {
        case HTTPStatusCode.Forbidden:
          redirectToSignInPage(returnPath);
          return undefined as any; // Unreached, redirect above, for types
        case HTTPStatusCode.BadGateway:
        case HTTPStatusCode.ServiceUnavailable:
        case HTTPStatusCode.GatewayTimeout:
          throw new NoConnectionError(e);
      }
    }

    throw e;
  }
}

export const IdentityDataContext = createContext<IdentityData>(null as any);

export const IDENTITY_QUERY_KEY = 'identity';

/**
 * Provides basic session info like the user's current identity as well as their
 * currently selected company. If this info is not yet available, this component
 * does not render its children.
 */
export function IdentityDataProvider({ children }: HasChildren) {
  const identity = useQuery(
    [IDENTITY_QUERY_KEY],
    async () => fetchIdentityOrRedirect(window.location.pathname),
    {
      suspense: true,
    },
  ).data;

  expectDefinedOrThrow(
    identity,
    new UnauthenticatedError('User is not authenticated and did not redirect to sign in page'),
  );

  return <IdentityDataContext.Provider value={identity}>{children}</IdentityDataContext.Provider>;
}

export function useIdentity() {
  return useContext(IdentityDataContext);
}
