import { useIsOperator } from '@meterup/authorization';
import { OPERATOR_ACTIONS_GROUP_NAME, useCommand, useRegisterCommands } from '@meterup/command';
import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useDebounce } from 'use-debounce';

import type { VirtualDeviceForNavigationQuery } from '../../gql/graphql';
import { paths } from '../../constants';
import { VirtualDeviceType } from '../../gql/graphql';
import { virtualDeviceForNavigationQuery } from '../../routes/pages/VirtualDeviceRoot.page';
import { makeLink } from '../../utils/main_and_drawer_navigation';
import { deviceTypeToHardwareIconPropHardware } from '../../utils/types';
import { ValidAPDetailsTab } from '../Wireless/utils';

type VirtualDevice = NonNullable<VirtualDeviceForNavigationQuery['virtualDevice']>;

type Network = VirtualDevice['network'] & {
  companySlug: NonNullable<VirtualDevice['network']['companySlug']>;
};

type VirtualDeviceResultWithCompany = VirtualDevice & {
  network: Network;
};

// eslint-disable-next-line consistent-return
function getPathForVirtualDevice(virtualDevice: VirtualDeviceResultWithCompany): string {
  switch (virtualDevice.deviceType) {
    case VirtualDeviceType.AccessPoint:
    case VirtualDeviceType.Observer:
      return makeLink(paths.pages.AccessPointPage, {
        companyName: virtualDevice.network.companySlug,
        networkSlug: virtualDevice.network.slug,
        uuid: virtualDevice.UUID,
        tab: ValidAPDetailsTab.Insights,
      });
    case VirtualDeviceType.Controller:
      return makeLink(paths.pages.SecurityApplianceDetailPage, {
        companyName: virtualDevice.network.companySlug,
        networkSlug: virtualDevice.network.slug,
        uuid: virtualDevice.UUID,
        tab: ValidAPDetailsTab.Insights,
      });
    case VirtualDeviceType.PowerDistributionUnit:
      return makeLink(paths.pages.PowerDistributionUnitDetailPage, {
        companyName: virtualDevice.network.companySlug,
        networkSlug: virtualDevice.network.slug,
        uuid: virtualDevice.UUID,
        tab: ValidAPDetailsTab.Insights,
      });
    case VirtualDeviceType.Switch:
      return makeLink(paths.pages.SwitchDetailPage, {
        companyName: virtualDevice.network.companySlug,
        networkSlug: virtualDevice.network.slug,
        uuid: virtualDevice.UUID,
        tab: ValidAPDetailsTab.Insights,
      });
  }
}

export function useNavigateToVirtualDeviceCommand() {
  const isOperator = useIsOperator();
  const { state } = useCommand();
  const navigate = useNavigate();

  const [searchQuery] = useDebounce(state?.ui?.search ?? '', 250);

  const virtualDevice = useGraphQL(
    virtualDeviceForNavigationQuery,
    { uuid: searchQuery },
    {
      suspense: false,
      useErrorBoundary: false,
      enabled: !!searchQuery,
    },
  ).data?.virtualDevice;

  const { nodeFactory } = state;

  const nodes = useMemo(() => {
    if (isOperator && virtualDevice?.network?.companySlug) {
      return [
        nodeFactory.action({
          id: 'navigate-to-serial-number',
          group: OPERATOR_ACTIONS_GROUP_NAME,
          display: `Navigate to virtual device ${virtualDevice.UUID}`,
          label: `Navigate to virtual device ${virtualDevice.UUID}`,
          icon: deviceTypeToHardwareIconPropHardware(virtualDevice.deviceType),
          internal: true,
          async onSelect() {
            navigate(getPathForVirtualDevice(virtualDevice as VirtualDeviceResultWithCompany));
          },
        }),
      ];
    }

    return [];
  }, [isOperator, virtualDevice, navigate, nodeFactory]);

  useRegisterCommands(nodes, [virtualDevice, navigate, isOperator]);
}
