import type { AxiosResponse, RawAxiosRequestConfig } from 'axios';
import type { Options as SnakeCaseOptions } from 'snakecase-keys';
import axios from 'axios';
import camelCaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

import { getPortalUrl } from './config';

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: getPortalUrl(window.location),
});

export default axiosInstance;

function marshallData<S extends Record<string, unknown>>(resp: AxiosResponse<S>) {
  const { data } = resp;
  return camelCaseKeys(data, { deep: true });
}

function marshallVars<S extends Record<string, unknown>>(vars: S) {
  return snakecaseKeys<S, SnakeCaseOptions>(vars, { deep: true });
}

function apiProxyUrl(path: string) {
  return `/api-proxy/${path.replace(/^\//, '')}`;
}

export function marshallSuccessToBoolean<T = unknown>(resp: AxiosResponse<T>) {
  const { status } = resp;
  return status >= 200 && status < 300;
}

export function plainGet<
  T extends unknown,
  R extends AxiosResponse<T> = AxiosResponse<T>,
  D = unknown,
  P = unknown,
>(path: string, params?: RawAxiosRequestConfig<P>): Promise<T> {
  // @ts-ignore: 2345
  return axiosInstance.get<T, R, D>(apiProxyUrl(path), params || {});
}

export function get<
  T extends Record<string, any>,
  R extends AxiosResponse<T> = AxiosResponse<T>,
  D = Record<string, any>,
  P = unknown,
>(path: string, params?: P): Promise<T> {
  // @ts-ignore: 2345
  return plainGet<T, R, D, P>(path, { params }).then(marshallData);
}

export function plainPut<
  T extends unknown,
  R extends AxiosResponse<T> = AxiosResponse<T>,
  D = unknown,
>(path: string, data: D, config?: RawAxiosRequestConfig<D>): Promise<R> {
  return axiosInstance.put<T, R, D>(apiProxyUrl(path), data, config || {});
}

type PutRestParams<T, D> = {
  config?: RawAxiosRequestConfig<D>;
  marshall?: (data: D) => T;
};

export function put<
  T extends unknown,
  R extends AxiosResponse<T> = AxiosResponse<T>,
  D extends Record<string, unknown> = {},
>(path: string, vars: D, rest?: PutRestParams<T, D>): Promise<T> {
  const config = rest?.config || {};
  const marshall = rest?.marshall || marshallData;
  // @ts-ignore: 2345
  return plainPut<T, R, D>(path, marshallVars(vars), config).then(marshall);
}

export function plainPost<
  T extends unknown,
  R extends AxiosResponse<T> = AxiosResponse<T>,
  D = unknown,
>(path: string, data: D, config?: RawAxiosRequestConfig<D>): Promise<R> {
  return axiosInstance.post<T, R, D>(apiProxyUrl(path), data, config || {});
}

export function post<
  T extends Record<string, unknown>,
  R extends AxiosResponse<T> = AxiosResponse<T>,
  D extends Record<string, unknown> = {},
>(path: string, vars: D): Promise<T> {
  // @ts-ignore: 2345
  return plainPost<T, R, D>(path, marshallVars(vars)).then(marshallData);
}

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
export function delete_<
  T extends Record<string, any>,
  R extends AxiosResponse<T> = AxiosResponse<T>,
  D = any,
>(path: string): Promise<T> {
  // @ts-ignore: 2345
  return axiosInstance.delete<T, R, D>(apiProxyUrl(path)).then(marshallData);
}
