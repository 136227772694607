import 'virtual:vite-plugin-sentry/sentry-config';

import { httpClientIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';

const { dist, release } = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG;

Sentry.init({
  dsn: import.meta.env.SENTRY_DSN,
  tracePropagationTargets: ['portal.meter.dev', 'portal.meter.com'],
  integrations: [Sentry.browserTracingIntegration(), httpClientIntegration()],
  enabled: import.meta.env.PROD,
  environment: import.meta.env.REALM,
  dist,
  release,
  tracesSampleRate: 0.25,
});
