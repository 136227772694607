import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';

import type { CompanyQuery } from '../gql/graphql';
import { graphql } from '../gql';

export const companyQuery = graphql(`
  query Company($slug: String!) {
    getCompany(slug: $slug) {
      uuid
      name
      slug
      isCustomer
      supportGroup
    }
  }
`);

export type Company = CompanyQuery['getCompany'];

export function useCompany(slug: string | undefined): Company | null {
  const company = useGraphQL(companyQuery, { slug: slug! }, { enabled: !!slug }).data?.getCompany;
  return useMemo(() => company ?? null, [company]);
}
