import { type UseStorageOptions } from '@meterup/common';
import { useLocalStorage as useBaseLocalStorage } from '@meterup/common';

import { logError } from '../utils/logError';

export const useLocalStorage = <T>(
  key: string,
  initialValue: T,
  { poll }: Exclude<UseStorageOptions, 'logError'> = {},
) => useBaseLocalStorage(key, initialValue, { poll, logError });
