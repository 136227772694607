import { isEmpty } from 'lodash-es';

export function isDefined<T>(value: T | null | undefined): value is T {
  return typeof value !== 'undefined' && value != null;
}

export function isDefinedAndNotEmpty<T>(value: T | null | undefined): value is T {
  // Lodash isEmpty doesn't handle numbers or booleans in an expected way (always returns true)
  // Modify so that numbers return true if they're non-zero, and booleans return true if true
  if (typeof value === 'number') {
    return isDefined(value) && !(Number.isNaN(value) || value === 0);
  }
  if (typeof value === 'boolean') {
    return isDefined(value) && value;
  }
  return isDefined(value) && !isEmpty(value);
}
