import { DemoModeContext } from '@meterup/authorization';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { DEMO_COMPANY_SLUG, paths } from '../constants';
import { useCurrentCompanyFromPathOrNull } from '../hooks/useCurrentCompanyFromPath';
import { useFeatureFlags } from '../hooks/useFeatureFlags';
import { makeLink } from '../utils/main_and_drawer_navigation';

export function EnforceDemoCompanyEffect() {
  const company = useCurrentCompanyFromPathOrNull();
  const flags = useFeatureFlags();
  const { isDemoMode } = useContext(DemoModeContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (flags['demo-mode-v2'] && isDemoMode && company?.company_slug !== DEMO_COMPANY_SLUG) {
      navigate(makeLink(paths.pages.CompanyRootPage, { companyName: DEMO_COMPANY_SLUG }));
    }
  }, [isDemoMode, company, navigate, flags]);

  return null;
}
