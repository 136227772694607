import React, { useContext, useMemo } from 'react';

import { useCurrentCompanyOrDefault } from './CurrentCompanyProvider';
import { useIdentity } from './IdentityDataProvider';

interface RoleData {
  role: string;
}
export const RoleContext = React.createContext<RoleData>({} as any);

export default function RoleProvider({ children }: { children?: React.ReactNode }) {
  const identity = useIdentity();
  const currentCompany = useCurrentCompanyOrDefault();

  const value = useMemo(() => {
    const membership = identity.company_memberships.find((c) => c.company_slug === currentCompany);
    if (membership) {
      return { role: membership.role };
    }

    return { role: 'guest' };
  }, [identity, currentCompany]);

  return <RoleContext.Provider value={value}>{children}</RoleContext.Provider>;
}

export const useCurrentRoleOrGuest = () => {
  const currentRole = useContext(RoleContext);
  return currentRole?.role ?? 'guest';
};
