import { isDefined } from '@meterup/common';
import { useContext } from 'react';

import { DemoModeContext } from '../providers/DemoModeProvider';

export function useIsDemoMode() {
  const val = useContext(DemoModeContext);

  if (!isDefined(val)) {
    throw new Error('useIsDemoMode must be used within a DemoModeContext');
  }

  return val.isDemoMode;
}
