import { v4 } from 'uuid';

export const METER_REQUEST_ID_HEADER = 'X-Meter-Frontend-Request-ID';

export function requestID() {
  return v4();
}

export function generateHeaders() {
  return {
    [METER_REQUEST_ID_HEADER]: requestID(),
  };
}
