// Same regex that we use in backend validation via `validator` package
// https://github.com/meterup/api/blob/0a201f7e6574ab65d0ff155f52c09b92eea17742/vendor/github.com/go-playground/validator/v10/regexes.go#L52
const FQDN_RE =
  /^([a-zA-Z0-9]{1}[a-zA-Z0-9-]{0,62})(\.[a-zA-Z0-9]{1}[a-zA-Z0-9-]{0,62})*?(\.[a-zA-Z]{1}[a-zA-Z0-9]{0,62})\.?$/;

export function isFQDN(str: string): boolean {
  return FQDN_RE.test(str);
}

const WILDCARD_DOMAIN_RE = /^[-a-zA-Z0-9.*]+$/;

const INVALID_CHARACTERS = ['**', ',', '*.*', '?', '{', '}', '[', ']', '\\\\', '!'];

export function isWildcardDomain(input: string): boolean {
  return WILDCARD_DOMAIN_RE.test(input) && !INVALID_CHARACTERS.some((v) => input.includes(v));
}
