import { datadogRum } from '@datadog/browser-rum';
import { useIsDemoMode } from '@meterup/authorization';
import { isDefined } from '@meterup/common';
import * as Sentry from '@sentry/react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import posthog from 'posthog-js';
import { useEffect } from 'react';

import { useCompany } from './hooks/useCompany';
import { useCurrentCompanyFromPathOrNull } from './hooks/useCurrentCompanyFromPath';
import { useNetworkOrNull } from './hooks/useNetworkFromPath';
import { useIdentity } from './providers/IdentityDataProvider';
import { logError } from './utils/logError';

export function LaunchDarklyIdentifyEffect() {
  const LDClient = useLDClient();
  const identity = useIdentity();
  const currentCompany = useCurrentCompanyFromPathOrNull();
  const isDemoMode = useIsDemoMode();

  const company = useCompany(currentCompany?.company_slug);
  const network = useNetworkOrNull();

  useEffect(() => {
    if (isDefined(LDClient) && isDefined(identity)) {
      LDClient.identify({
        kind: 'user',
        key: identity.id.toString(),
        email: isDemoMode ? undefined : identity.username,
        custom: {
          company_slugs: identity.company_memberships.map((membership) => membership.company_slug),
          current_company: currentCompany?.company_slug,
          support_group: company?.supportGroup,
          is_customer: company?.isCustomer,
          is_trial: network?.isTrial,
        },
      });
    }
  }, [
    LDClient,
    currentCompany?.company_slug,
    network?.isTrial,
    company?.supportGroup,
    company?.isCustomer,
    identity,
    isDemoMode,
  ]);

  return null;
}

export function UserIdentityEffect() {
  const identity = useIdentity();
  const currentCompany = useCurrentCompanyFromPathOrNull();

  const company = useCompany(currentCompany?.company_slug);
  const network = useNetworkOrNull();

  useEffect(() => {
    if (isDefined(identity)) {
      const baseUser = {
        id: identity.id.toString(),
        email: identity.username,
        email_domain: identity.username.split('@')[1],
        current_company: currentCompany?.company_slug,
        support_group: company?.supportGroup,
        is_customer: company?.isCustomer,
        is_trial: network?.isTrial,
      };
      if (isDefined(Sentry)) {
        Sentry.setUser({
          ...baseUser,
          company_slugs: identity.company_memberships.map((membership) => membership.company_slug),
        });
      }
      datadogRum.setUser(baseUser);
    }
  }, [
    currentCompany?.company_slug,
    company?.supportGroup,
    company?.isCustomer,
    network?.isTrial,
    identity,
  ]);

  return null;
}

async function measureMemoryUsage() {
  let result;
  try {
    // @ts-ignore
    result = await performance?.measureUserAgentSpecificMemory();
  } catch (error) {
    if (error instanceof DOMException && error.name === 'SecurityError') {
      logError('The context must be secure to use performance.measureUserAgentSpecificMemory.');
      return null;
    }
  }
  return result;
}

export function PostHogIdentifyEffect() {
  const identity = useIdentity();
  const currentCompany = useCurrentCompanyFromPathOrNull();
  const company = useCompany(currentCompany?.company_slug);
  const network = useNetworkOrNull();

  useEffect(() => {
    if (isDefined(identity)) {
      posthog.identify(identity.id.toString(), {
        email: identity.username,
        email_domain: identity.username.split('@')[1],
        current_company: currentCompany?.company_slug,
        support_group: company?.supportGroup,
        is_customer: company?.isCustomer,
        is_trial: network?.isTrial,
      });
    }
  }, [
    currentCompany?.company_slug,
    company?.supportGroup,
    company?.isCustomer,
    network?.isTrial,
    identity,
  ]);

  useEffect(() => {
    // record memory footprint every ~15 minutes, an arbitrarily/random large session length interval, to start
    const recordMemoryInterval = window.setInterval(
      async () => {
        if (
          import.meta.env.NODE_ENV !== 'development' &&
          window.crossOriginIsolated &&
          'measureUserAgentSpecificMemory' in performance
        ) {
          const memory = await measureMemoryUsage();
          if (memory?.bytes) {
            posthog.capture('memory_footprint', {
              current_company: currentCompany?.company_slug,
              email: identity.username,
              app_memory: memory.bytes,
            });
          }
        }
      },
      -Math.log(Math.random()) * 15 * 60 * 1000,
    );

    return () => {
      if (recordMemoryInterval) {
        clearInterval(recordMemoryInterval);
      }
    };
  }, [currentCompany?.company_slug, identity.username]);

  return null;
}
