import { useIsOperator } from '@meterup/authorization';
import { OPERATOR_ACTIONS_GROUP_NAME, useCommand, useRegisterCommands } from '@meterup/command';
import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useDebounce } from 'use-debounce';

import type { HardwareDeviceForNavigationQuery } from '../../gql/graphql';
import { paths } from '../../constants';
import { graphql } from '../../gql';
import { DeviceType } from '../../gql/graphql';
import { makeLink } from '../../utils/main_and_drawer_navigation';
import { deviceTypeToHardwareIconPropHardware } from '../../utils/types';
import { ValidAPDetailsTab } from '../Wireless/utils';

const hardwareDeviceForNavigationQuery = graphql(`
  query HardwareDeviceForNavigation($serialNumber: String!) {
    hardwareDevice(serialNumber: $serialNumber) {
      serialNumber
      deviceType
      deviceModel

      virtualDevice {
        UUID
        label
        description

        network {
          UUID
          slug

          companySlug
        }
      }
    }
  }
`);

type HardwareDeviceResult = HardwareDeviceForNavigationQuery['hardwareDevice'];

type VirtualDevice = NonNullable<HardwareDeviceResult['virtualDevice']>;

type Network = VirtualDevice['network'] & {
  companySlug: NonNullable<VirtualDevice['network']['companySlug']>;
};

type HardwareDeviceResultWithCompany = HardwareDeviceResult & {
  virtualDevice: VirtualDevice & {
    network: Network;
  };
};

// eslint-disable-next-line consistent-return
function getPathForHardwareDevice(hardwareDevice: HardwareDeviceResultWithCompany): string {
  switch (hardwareDevice.deviceType) {
    case DeviceType.AccessPoint:
      return makeLink(paths.pages.AccessPointPage, {
        companyName: hardwareDevice.virtualDevice.network.companySlug,
        networkSlug: hardwareDevice.virtualDevice.network.slug,
        uuid: hardwareDevice.virtualDevice.UUID,
        tab: ValidAPDetailsTab.Insights,
      });
    case DeviceType.Controller:
      return makeLink(paths.pages.SecurityApplianceDetailPage, {
        companyName: hardwareDevice.virtualDevice.network.companySlug,
        networkSlug: hardwareDevice.virtualDevice.network.slug,
        uuid: hardwareDevice.virtualDevice.UUID,
        tab: ValidAPDetailsTab.Insights,
      });
    case DeviceType.PowerDistributionUnit:
      return makeLink(paths.pages.PowerDistributionUnitDetailPage, {
        companyName: hardwareDevice.virtualDevice.network.companySlug,
        networkSlug: hardwareDevice.virtualDevice.network.slug,
        uuid: hardwareDevice.virtualDevice.UUID,
        tab: ValidAPDetailsTab.Insights,
      });
    case DeviceType.Switch:
      return makeLink(paths.pages.SwitchDetailPage, {
        companyName: hardwareDevice.virtualDevice.network.companySlug,
        networkSlug: hardwareDevice.virtualDevice.network.slug,
        uuid: hardwareDevice.virtualDevice.UUID,
        tab: ValidAPDetailsTab.Insights,
      });
  }
}

export function useNavigateToSerialNumberCommand() {
  const isOperator = useIsOperator();
  const { state } = useCommand();
  const navigate = useNavigate();

  const [searchQuery] = useDebounce(state?.ui?.search ?? '', 250);

  const hardwareDevice = useGraphQL(
    hardwareDeviceForNavigationQuery,
    { serialNumber: searchQuery },
    {
      suspense: false,
      useErrorBoundary: false,
      enabled: !!searchQuery,
    },
  ).data?.hardwareDevice;

  const { nodeFactory } = state;

  const nodes = useMemo(() => {
    if (isOperator && hardwareDevice?.virtualDevice?.network?.companySlug) {
      return [
        nodeFactory.action({
          id: 'navigate-to-serial-number',
          group: OPERATOR_ACTIONS_GROUP_NAME,
          display: `Navigate to serial number ${hardwareDevice.serialNumber}`,
          label: `Navigate to serial number ${hardwareDevice.serialNumber}`,
          icon: deviceTypeToHardwareIconPropHardware(hardwareDevice.deviceType),
          internal: true,
          async onSelect() {
            navigate(getPathForHardwareDevice(hardwareDevice as HardwareDeviceResultWithCompany));
          },
        }),
      ];
    }

    return [];
  }, [isOperator, hardwareDevice, navigate, nodeFactory]);

  useRegisterCommands(nodes, [hardwareDevice, navigate, isOperator]);
}
