import { makeQueryKey, useGraphQL } from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import type { GetCompanyUserInput } from '../../../gql/graphql';
import { graphql } from '../../../gql';

graphql(`
  fragment CompanyUserBody on CompanyUser {
    roles {
      companySlug
      name
      networkUUID
    }
    createdAt
    deletedAt
    UUID
    companyMembershipRole
    user {
      email
      firstName
      lastName
    }
  }
`);

const getCompanyUsers = graphql(`
  query GetCompanyUsers($companySlug: String!, $input: GetCompanyUserInput) {
    companyUsers(companySlug: $companySlug, input: $input) {
      ...CompanyUserBody
    }
  }
`);

export function useCompanyUsers(companySlug: string, input: GetCompanyUserInput = {}) {
  const params: GetCompanyUserInput = {
    includeDeleted: true,
    ...input,
  };
  return useGraphQL(getCompanyUsers, { companySlug, input: params });
}

export function useInvalidateCompanyUsers(companySlug: string) {
  const client = useQueryClient();
  return useCallback(() => {
    const queryKey = makeQueryKey(getCompanyUsers, { companySlug });
    client.invalidateQueries(queryKey);
  }, [client, companySlug]);
}

const getCompanyUser = graphql(`
  query GetCompanyUser($uuid: UUID!, $input: GetCompanyUserInput) {
    companyUser(companyUserUUID: $uuid, input: $input) {
      ...CompanyUserBody
    }
  }
`);

export function useCompanyUser(sid: string, input: GetCompanyUserInput = {}) {
  const params: GetCompanyUserInput = {
    includeDeleted: true,
    ...input,
  };
  return useGraphQL(getCompanyUser, { uuid: sid, input: params });
}

export function useInvalidateCompanyUser() {
  const client = useQueryClient();
  return useCallback(
    (sid: string) => {
      const queryKey = makeQueryKey(getCompanyUser, { uuid: sid });
      client.invalidateQueries(queryKey);
    },
    [client],
  );
}

export function useInvalidateCompanyUserAndUsers(companySlug: string) {
  const invalidateCompanyUser = useInvalidateCompanyUser();
  const invalidateCompanyUsers = useInvalidateCompanyUsers(companySlug);
  return useCallback(
    (sid: string) => {
      invalidateCompanyUser(sid);
      invalidateCompanyUsers();
    },
    [invalidateCompanyUser, invalidateCompanyUsers],
  );
}

export const deleteCompanyUser = graphql(`
  mutation DeleteCompanyUser($uuid: UUID!) {
    deleteCompanyUser(companyUserUUID: $uuid) {
      UUID
    }
  }
`);

export const reactivateCompanyUser = graphql(`
  mutation ReactivateCompanyUser($uuid: UUID!) {
    activateCompanyUser(companyUserUUID: $uuid) {
      UUID
    }
  }
`);

export const createCompanyUser = graphql(`
  mutation CreateCompanyUser($companySlug: String!, $input: CreateCompanyUserInput!) {
    createCompanyUser(companySlug: $companySlug, input: $input) {
      UUID
    }
  }
`);

export const updateCompanyUser = graphql(`
  mutation UpdateCompanyUser($uuid: UUID!, $input: UpdateCompanyUserInput!) {
    updateCompanyUser(companyUserUUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const updateUserRoles = graphql(`
  mutation UpdateUserRoles($uuid: UUID!, $input: [UserRoleInput!]!) {
    updateUserRoles(companyUserUUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const assignOperatorRole = graphql(`
  mutation AssignOperatorRole($uuid: UUID!) {
    assignOperatorRole(companyUserUUID: $uuid) {
      UUID
    }
  }
`);

export const removeOperatorRole = graphql(`
  mutation RemoveOperatorRole($uuid: UUID!) {
    removeOperatorRole(companyUserUUID: $uuid) {
      UUID
    }
  }
`);
