import axios, { type AxiosRequestConfig } from 'axios';

import { generateHeaders } from '../helpers/requestID';

export const APIBaseURL = import.meta.env.REACT_APP_API_URL;
export const APIProxyBaseURL = `${APIBaseURL}/api-proxy`;

export const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: APIProxyBaseURL,
});

function requestIDInterceptor<C extends AxiosRequestConfig<any>>(config: C): C {
  return {
    ...config,
    headers: {
      ...config.headers,
      ...generateHeaders(),
    },
  };
}

axios.interceptors.request.use(requestIDInterceptor, (error) => Promise.reject(error));
