import {
  Body,
  Button,
  EmptyState,
  Heading,
  Layout,
  LayoutPage,
  Link,
  Logo,
  sizing,
  styled,
  VStack,
} from '@meterup/atto';
import React from 'react';

import { SUPPORT_URL } from '../constants';
import { useFeatureFlags } from '../hooks/useFeatureFlags';
import Markdown from './Markdown';

const MaintenanceMessageSubheading = styled('div', {
  vStack: '$12',
  padding: sizing.primary,
});
MaintenanceMessageSubheading.displayName = 'MaintenanceMessageSubheading';

export function DefaultMaintenanceSubHeading() {
  return (
    <>
      <Body>
        <p>Our team is working to restore access as soon as possible.</p>
      </Body>
      <Body>
        <p>
          Your local network and internet connection (including Wi-Fi) are not affected and will
          continue functioning normally.
        </p>
      </Body>
    </>
  );
}

export function GlobalMaintenanceScreen({ subheading }: { subheading?: React.ReactNode }) {
  return (
    <Layout>
      <LayoutPage>
        <EmptyState
          heading={
            <VStack spacing={24} justify="center" align="center">
              <Logo size={40} type="word" />
              <Heading size={24}>Dashboard temporarily unavailable</Heading>
            </VStack>
          }
          subheading={
            <MaintenanceMessageSubheading>
              {subheading || <DefaultMaintenanceSubHeading />}
            </MaintenanceMessageSubheading>
          }
          action={
            <Button as={Link} href={SUPPORT_URL} arrangement="leading-icon" icon="life-ring">
              Meter Support
            </Button>
          }
        />
      </LayoutPage>
    </Layout>
  );
}

/**
 * This intentionally runs before any authentication checks, because if the API is hard down then
 * authentication will fail.
 */
export default function GlobalMaintenanceKillswitch({ children }: { children?: React.ReactNode }) {
  const flags = useFeatureFlags();

  const message = flags['dangerous-global-maintenance-killswitch-message']?.trim();

  if (message) {
    return <GlobalMaintenanceScreen subheading={<Markdown>{message}</Markdown>} />;
  }

  return children;
}
