import { ALERT_BANNER_SCHEMA } from '@meterup/common/src/helpers/alertBanner';
import * as z from 'zod';

export const LD_FEATURE_FLAGS = z.object({
  'alert-audit-log': z.boolean(),
  'alert-banners': z.array(ALERT_BANNER_SCHEMA), // Display service alert banners in dashboard
  'ap-charts': z.boolean(),
  'app-login-session-token': z.boolean(),
  'captive-portal': z.boolean(),
  'client-wired-wireless-filter-ui': z.boolean(), // Show or hide client wired/wireless filter UI
  'clients-chart': z.boolean(),
  'client-isolation': z.boolean(),
  'command-dock-product': z.boolean(), // Show Command dock target product link
  'config-2-hub-uptime-graphs': z.boolean(), // Show or hide uptime graphs in hub
  'content-filtering-ui': z.boolean(), // Show or hide DNS security UI
  'controller-events': z.boolean(), // Display the controller event logs
  'controller-ping-ui': z.boolean(), // Show or hide ping test in settings dialog
  'dangerous-global-maintenance-killswitch-message': z.string().nullish(), // Global maintenance message, supports markdown
  'dashboard-speed-test': z.boolean(), // Show or hide speed tests on overview
  'device-config-operators': z.boolean(),
  'dhcp-rule-filters': z.boolean(),
  'dns-security': z.boolean(), // DNS security with Config 2 COS and Netstar
  'edit-vlan': z.boolean(), // Allow editing or adding VLANs
  'event-log-isp-chart': z.boolean(),
  'firewall-rules': z.boolean(),
  'firewall-rules-wan': z.boolean(),
  'firewall-rules-inter-vlan': z.boolean(),
  'hotspot-20': z.boolean(),
  'host-monitoring': z.boolean(),
  'host-monitoring-availability-charts': z.boolean(),
  'ipsec-tunnels': z.boolean(),
  isp: z.boolean(),
  'multicast-mdns': z.boolean(),
  'network-mutation-audit-log': z.boolean(),
  'onboarding-form': z.boolean(),
  'onboarding-form-after-live': z.boolean(),
  'one-to-one-nat': z.boolean(),
  'policy-routing': z.boolean(),
  'port-forwarding': z.boolean(),
  'power-distribution-units': z.boolean(),
  'rack-elevation': z.boolean(),
  'rate-limiting': z.boolean(),
  'rf-utilization-chart': z.boolean(),
  'service-discovery': z.boolean(),
  'site-to-site-vpn': z.boolean(),
  'sos-ui': z.boolean(),
  'ssid-scheduling': z.boolean(),
  'stats-log': z.boolean(),
  'support-form': z.boolean(),
  'switch-auto-config': z.boolean(),
  'tablet-splash-screen': z.boolean(),
  'tunnel-ui': z.boolean(), // Show or hide tunnel UI
  'wos-acs': z.boolean(), // Enables Auto-Channel-Selection for APs
  'demo-mode-v2': z.boolean(), // Whether we enforce the new demo mode rules
  'notification-integrations': z.boolean(), // Whether to show the integrations link in settings
  'notification-settings': z.boolean(), // Whether to show the notification settings link in settings
  'wan-throughput-lookback': z.boolean(),
  'external-captive-portal': z.boolean(),
  'webhook-integrations': z.boolean(),
  'alert-params': z.boolean(),
  'default-role-company': z.boolean(),
  'set-permitted-email-domain': z.boolean(),
});

export interface MeterLDFlags extends Partial<z.TypeOf<typeof LD_FEATURE_FLAGS>> {}

export const LOCAL_LD_FLAG_VALUES: MeterLDFlags = {
  'alert-audit-log': true,
  'alert-banners': [],
  'ap-charts': false,
  'app-login-session-token': true,
  'captive-portal': true,
  'client-wired-wireless-filter-ui': true,
  'clients-chart': false,
  'client-isolation': true,
  'command-dock-product': false,
  'config-2-hub-uptime-graphs': false,
  'content-filtering-ui': true,
  'controller-events': true,
  'controller-ping-ui': false,
  'dangerous-global-maintenance-killswitch-message': null,
  'dashboard-speed-test': true,
  'device-config-operators': true,
  'dhcp-rule-filters': true,
  'dns-security': true,
  'event-log-isp-chart': true,
  'edit-vlan': true,
  'firewall-rules': true,
  'firewall-rules-wan': true,
  'firewall-rules-inter-vlan': true,
  'hotspot-20': false,
  'host-monitoring': true,
  'host-monitoring-availability-charts': true,
  'ipsec-tunnels': true,
  isp: true,
  'multicast-mdns': true,
  'network-mutation-audit-log': false,
  'onboarding-form': true,
  'onboarding-form-after-live': false,
  'one-to-one-nat': true,
  'policy-routing': true,
  'port-forwarding': true,
  'power-distribution-units': true,
  'rack-elevation': true,
  'rate-limiting': true,
  'rf-utilization-chart': false,
  'service-discovery': true,
  'site-to-site-vpn': true,
  'sos-ui': true,
  'stats-log': false,
  'ssid-scheduling': true,
  'support-form': false,
  'switch-auto-config': false,
  'tablet-splash-screen': true,
  'tunnel-ui': true,
  'wos-acs': true,
  'demo-mode-v2': false,
  'notification-integrations': true,
  'notification-settings': true,
  'wan-throughput-lookback': true,
  'external-captive-portal': true,
  'webhook-integrations': false,
  'alert-params': true,
  'default-role-company': false,
  'set-permitted-email-domain': false,
};

export const PH_FEATURE_FLAGS = z.object({
  config10: z.boolean(),
});

export interface MeterPHFlags extends Partial<z.TypeOf<typeof PH_FEATURE_FLAGS>> {}

export const LOCAL_PH_FLAG_VALUES: MeterPHFlags = {
  config10: true,
};
