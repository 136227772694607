import { ZendeskProvider as BaseZendeskProvider } from '@meterup/react-use-zendesk';
import posthog from 'posthog-js';

import { useCurrentCompanyFromPathOrNull } from '../hooks/useCurrentCompanyFromPath';
import { useIdentity } from './IdentityDataProvider';

export function ZendeskProvider({ children }: { children: React.ReactNode }) {
  const currentCompany = useCurrentCompanyFromPathOrNull();
  const identity = useIdentity();

  return (
    <BaseZendeskProvider
      apiKey={import.meta.env.ZENDESK_API_KEY}
      onOpen={() => {
        posthog.capture('zendesk:opened', {
          current_company: currentCompany?.company_slug,
          email: identity.username,
        });
      }}
    >
      {children}
    </BaseZendeskProvider>
  );
}
