import { isDefined } from '@meterup/common';
import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router';

import type { IdentityData } from '../api/types';
import { paths } from '../constants';
import { WorkspaceNotFoundError } from '../errors';
import { useIdentity } from '../providers/IdentityDataProvider';

export function findIdentityCompanyMembership(identity: IdentityData, companyName: string) {
  return identity.company_memberships.find((c) => c.company_slug === companyName) ?? null;
}

function useCurrentCompanyNameFromPath() {
  const location = useLocation();

  return matchPath(
    {
      path: paths.pages.CompanyRootPage,
      end: false,
    },
    location.pathname,
  )?.params.companyName;
}

export function useCurrentCompanyFromPathOrNull() {
  const identity = useIdentity();
  const companyNameFromPath = useCurrentCompanyNameFromPath();

  const currentCompany = useMemo(
    () =>
      companyNameFromPath ? findIdentityCompanyMembership(identity, companyNameFromPath) : null,
    [companyNameFromPath, identity],
  );

  return currentCompany;
}

export function useCurrentCompanyFromPath() {
  const companyNameFromPath = useCurrentCompanyNameFromPath();
  const currentCompany = useCurrentCompanyFromPathOrNull();

  if (!isDefined(currentCompany)) {
    throw new WorkspaceNotFoundError(
      'Workspace not found',
      `The workspace "${companyNameFromPath}" does not exist or you do not have permission to access it. Please contact the workspace administrator or support if you believe this is an error.`,
    );
  }

  return currentCompany;
}
