/* eslint-disable max-classes-per-file */
import type { DisplayableError } from '@meterup/common';

export class UnauthenticatedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'UnauthenticatedError';
  }
}

export class NoCompanyMembershipsError extends Error implements DisplayableError {
  readonly isDisplayableError = true;

  constructor(
    readonly displayTitle: string,
    readonly displayMessage: string,
  ) {
    super(displayMessage);
    this.name = 'NoCompanyMembershipsError';
  }
}

export class WorkspaceNotFoundError extends Error implements DisplayableError {
  readonly isDisplayableError = true;

  constructor(
    readonly displayTitle: string,
    readonly displayMessage: string,
  ) {
    super(displayMessage);
    this.name = 'WorkspaceNotFoundError';
  }
}

export class NoHardwareError extends Error implements DisplayableError {
  readonly isDisplayableError = true;

  constructor(
    readonly displayTitle: string,
    readonly displayMessage: string,
  ) {
    super(displayMessage);
    this.name = 'NoHardwareError';
  }
}
