/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import React from 'react';
import tinykeys from 'tinykeys';

import { Action, Directory, VisualState } from '../../core';
import { useCommand } from '../Root';

export function useCommandKeyboardNavigation() {
  const { state } = useCommand();

  const visible = state.ui.visualState === VisualState.Entered;

  // Navigation
  React.useEffect(() => {
    const next = (event: KeyboardEvent) => {
      event.preventDefault();
      const { activeIndex } = state.ui;
      let targetIndex = activeIndex + 1;
      if (targetIndex > state.filter.ordered.length - 1) {
        return;
      }
      const targetNode = state.filter.ordered[targetIndex];
      if (typeof targetNode === 'string') {
        targetIndex += 1;
      }
      state.ui.update((ui) => {
        ui.activeIndex = targetIndex;
      });
    };

    const prev = (event: KeyboardEvent) => {
      event.preventDefault();
      const { activeIndex } = state.ui;
      let targetIndex = activeIndex - 1;
      if (targetIndex < 0) {
        return;
      }
      const targetNode = state.filter.ordered[targetIndex];
      if (typeof targetNode === 'string') {
        if (activeIndex === 1) {
          return;
        }
        targetIndex -= 1;
      }
      state.ui.update((ui) => {
        ui.activeIndex = targetIndex;
      });
    };

    if (visible) {
      const unsubscribe = tinykeys(window, {
        ArrowUp: prev,
        ArrowDown: next,
        'Shift+Tab': prev,
        Tab: next,
        'Control+n': next,
        'Control+p': prev,
      });
      return () => {
        unsubscribe();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  // Reset activeIndex & search query on directory change
  React.useEffect(() => {
    const results = state.filter.ordered;

    if (!results) {
      state.ui.update((ui) => {
        ui.activeIndex = 0;
      });

      return;
    }

    const targetIndex = 0;
    const currentResult = results[targetIndex];

    state.ui.update((ui) => {
      ui.activeIndex = typeof currentResult === 'string' ? 1 : 0;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentRoot, state.ui.search, state.filter.ordered]);

  // Reset search query on directory change
  React.useEffect(() => {
    state.ui.update((ui) => {
      ui.search = '';
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentRoot]);

  // Navigate directories; select item
  React.useEffect(() => {
    const unsubscribe = tinykeys(window, {
      Enter: () => {
        const node = state.ui.activeNode?.node;
        if (node) {
          if (node instanceof Action) {
            node.onSelect(node);
          } else if (node instanceof Directory) {
            state.updateCurrentRoot(node);
          }
        }
      },
      Backspace: () => {
        if (!state.ui.search && state.currentRoot.parent) {
          state.updateCurrentRoot(state.currentRoot.parent);
        }
      },
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
