import * as z from 'zod';

export const ALERT_BANNER_SCHEMA = z.object({
  id: z.string(),
  severity: z.enum(['low', 'medium', 'high']),
  message: z.string(),
  controllerName: z.string().nullish(), // controller ID
  companyName: z.string().nullish(), // company slug
  links: z
    .array(
      z.object({
        url: z.string(),
        text: z.string(),
      }),
    )
    .nullish(),
});

export type AlertBannerSchema = z.infer<typeof ALERT_BANNER_SCHEMA>;
