/**
 * Determines if a value is truthy (not false, null, undefined, empty string, or 0).
 *
 * Example usage:
 * ```
 * const nameFields = [user.firstName, user.lastName].filter(Truthy);
 * ```
 * This will result in an array containing only non-empty, non-undefined, non-null, etc., values.
 *
 * @param value - The value to check.
 * @returns - A boolean indicating if the value is truthy.
 */
export function truthy<T>(value: T): value is Exclude<T, false | null | undefined | '' | 0> {
  return Boolean(value);
}
