import type { RoleAssignment, RoleName, UseRolesQueryQuery } from '../gql/graphql';
import type {
  AllGlobalOrSpecific,
  GetRolesForCompanyParams,
  GetRolesMultiNetworkParams,
  GetRolesParams,
  GlobalCompanyType,
  GlobalNetworkType,
  HasRoleParams,
} from './typesHelpers';
import {
  All,
  getRolesForCompanyParamsType,
  getRolesMultiNetworkParamsType,
  GlobalCompany,
  GlobalNetwork,
  hasRoleParamsType,
  isAll,
  isGlobal,
} from './typesHelpers';

export interface AuthorizationState {
  get(args: GetRolesParams): RoleAssignment[];
  isEqual(other?: AuthorizationState): boolean;

  check(args: HasRoleParams): boolean;
}

const typeParamName = '__type__' as const;

function isHasRoleParams(args: GetRolesParams): args is HasRoleParams {
  return args[typeParamName] === hasRoleParamsType;
}

function isGetRolesMultiNetworkParams(args: GetRolesParams): args is GetRolesMultiNetworkParams {
  return args[typeParamName] === getRolesMultiNetworkParamsType;
}

function isGetRolesForCompanyParams(args: GetRolesParams): args is GetRolesForCompanyParams {
  return args[typeParamName] === getRolesForCompanyParamsType;
}

type CompanySlug = string | GlobalCompanyType;
type NetworkUUID = string | GlobalNetworkType;
type NetworkUUIDToRoleAssignment = Map<CompanySlug, RoleAssignment>;
type CompanySlugToNetworkUUID = Map<NetworkUUID, NetworkUUIDToRoleAssignment>;

function hasTypeName(obj: any): obj is { __typename: string } {
  return obj.__typename;
}

function isRoleAssignment(obj: any): obj is RoleAssignment {
  return hasTypeName(obj) && obj.__typename === 'RoleAssignment';
}

export function compareAssignments<K, V>(map1: Map<K, V>, map2: Map<K, V>): boolean {
  if (map1.size !== map2.size) {
    return false;
  }
  const idx = Array.from(map1.entries()).findIndex(([key, value]) => {
    const otherValue = map2.get(key);
    if (!otherValue) {
      return true;
    }
    if (otherValue instanceof Map && value instanceof Map) {
      return !compareAssignments(value, otherValue);
    }
    if (isRoleAssignment(value) && isRoleAssignment(otherValue)) {
      return value.name !== otherValue.name;
    }

    return true;
  });
  return idx === -1;
}

export type RoleAssignments = UseRolesQueryQuery['roles'];

function isDefined<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null;
}

function extractNetworkUUIDs(args: GetRolesParams): AllGlobalOrSpecific[] {
  const networkUUIDs: AllGlobalOrSpecific[] = [];
  if (isHasRoleParams(args) || isGetRolesForCompanyParams(args)) {
    networkUUIDs.push(args.networkUUID || GlobalNetwork);
  } else if (isGetRolesMultiNetworkParams(args)) {
    if (isGlobal(args.networkUUIDs) || isAll(args.networkUUIDs)) {
      networkUUIDs.push(GlobalNetwork);
    } else {
      networkUUIDs.push(...args.networkUUIDs);
    }
  }
  // Ensure uniqueness
  const set = new Set<AllGlobalOrSpecific>(networkUUIDs);
  return [...set.values()];
}

function extractRoleName(args: GetRolesParams): RoleName | typeof All {
  if (isHasRoleParams(args) || isGetRolesForCompanyParams(args)) {
    return args.roleName || All;
  }
  return All;
}

function extractAssignments(
  networkMappings: NetworkUUIDToRoleAssignment[],
  networkUUIDs: AllGlobalOrSpecific[],
): RoleAssignment[] {
  return networkMappings.flatMap((networkMapping) => {
    if (networkUUIDs.length === 0) {
      return [];
    }
    if (isAll(networkUUIDs[0])) {
      return [...networkMapping.values()];
    }
    const assignments: RoleAssignment[] = [];
    networkUUIDs.forEach((networkUUID) => {
      const networkUUIDMapping = networkMapping.get(networkUUID);
      if (isDefined(networkUUIDMapping)) {
        assignments.push(networkUUIDMapping);
      }
    });
    return assignments;
  });
}

/*
  Examples of how AuthorizationStateImpl#assignments can be populated:
  1. A user has a network admin assignment for company='company1' and network='network1':
    [{ company1: { network1: { name: RoleName.CompanyNetworkAdmin } } }]
  2. A user has a company admin assignment for company='company1':
    [{ company1: { [Global]: { name: RoleName.CompanyGlobalAdmin } } }]
  3. A user is an operator:
    [{ [Global]: { [Global]: { name: RoleName.Operator } } }]
  4. A user is a network admin for company='company1' and network='network1', but a regular user for
      company='company1' and network='network2':
    [
      { company1: { network1: { name: RoleName.CompanyNetworkAdmin } } },
      { company1: { network2: { name: RoleName.CompanyStandardUser } } },
    ]
 */
export class AuthorizationStateImpl implements AuthorizationState {
  private assignments: CompanySlugToNetworkUUID = new Map<
    CompanySlug,
    NetworkUUIDToRoleAssignment
  >();

  constructor(roleAssignments: RoleAssignments = []) {
    roleAssignments.forEach((assignment) => {
      const companySlug = assignment.companySlug || GlobalCompany;
      const networkUUID = assignment.networkUUID || GlobalNetwork;

      const companyAssignments =
        this.assignments.get(companySlug) || new Map<NetworkUUID, RoleAssignment>();
      companyAssignments.set(networkUUID, assignment);
      this.assignments.set(companySlug, companyAssignments);
    });
  }

  isEqual(other?: AuthorizationStateImpl): boolean {
    return (other && compareAssignments(this.assignments, other.assignments)) ?? false;
  }

  get(args: GetRolesParams): RoleAssignment[] {
    const companySlug = args.companySlug || GlobalCompany;
    const networkUUIDs = extractNetworkUUIDs(args);
    const roleName = extractRoleName(args);

    const networkMappings = this.extractNetworkMappings(companySlug);
    const assignments = extractAssignments(networkMappings, networkUUIDs);
    return assignments.filter((assignment) => roleName === All || assignment.name === roleName);
  }

  private extractNetworkMappings(companySlug: AllGlobalOrSpecific): NetworkUUIDToRoleAssignment[] {
    if (isAll(companySlug)) {
      return [...this.assignments.values()];
    }
    const companyAssignments = this.assignments.get(companySlug);
    return companyAssignments ? [companyAssignments] : [];
  }

  check(args: HasRoleParams): boolean {
    const { roleName, companySlug = GlobalCompany, networkUUID = GlobalNetwork } = args;
    return this.get(args).some(
      (assignment) =>
        assignment.companySlug === companySlug &&
        assignment.networkUUID === networkUUID &&
        assignment.name === roleName,
    );
  }
}
