import { BandWithIcon, HardwareIcon, ManufacturerIcon } from '@meterup/atto';
import React from 'react';
import { match } from 'ts-pattern';

import { styled } from '../stitches';
import { getManufacturerIconName } from '../topics/clients';
import { OnlineOfflineDraftBadge, OnlineOfflineStatusBadge } from './badges';

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const BandIcon = styled(BandWithIcon, {
  position: 'relative',
  zIndex: 1,
});

const BadgeRegion = styled('div', {
  position: 'relative',
  zIndex: 2,
  display: 'flex',
  marginTop: '-8px',
});

export function DeviceStatusIcon({ value }: { value: 'online' | 'offline' | string }) {
  const variant = match(value)
    .with('online', () => 'positive' as const)
    .with('offline', () => 'negative' as const)
    .with('draft', () => 'alternative' as const)
    .otherwise(() => 'neutral' as const);

  return (
    <Container>
      <BandIcon size="medium" variant={variant}>
        <HardwareIcon icon="access-point" size={32} variant="detailed" />
      </BandIcon>
      <BadgeRegion>
        <OnlineOfflineDraftBadge value={value} />
      </BadgeRegion>
    </Container>
  );
}

export function ClientStatusIcon({
  value,
  manufacturer,
}: {
  value: 'online' | 'offline' | string;
  manufacturer?: string;
}) {
  const variant = match(value)
    .with('online', () => 'positive' as const)
    .with('offline', () => 'negative' as const)
    .otherwise(() => 'neutral' as const);

  const icon = getManufacturerIconName(manufacturer ?? '');

  return (
    <Container>
      <BandWithIcon size="medium" variant={variant}>
        <ManufacturerIcon icon={icon} size={28} />
      </BandWithIcon>
      <BadgeRegion>
        <OnlineOfflineStatusBadge value={value} />
      </BadgeRegion>
    </Container>
  );
}
