import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { useCurrentCompanyFromPathOrNull } from '../hooks/useCurrentCompanyFromPath';
import { useNetworkSlugFromPathOrNull } from '../hooks/useNetworkFromPath';
import { useNetworksForCompany } from '../hooks/useNetworksForCompany';

export default function NetworkUUIDToSlugRedirect() {
  const company = useCurrentCompanyFromPathOrNull();
  const networkSlug = useNetworkSlugFromPathOrNull();
  const networks = useNetworksForCompany(company?.company_slug ?? null);
  const navigate = useNavigate();
  const location = useLocation();

  const networkSlugForUUID = useMemo(
    () => networks.find((network) => network.UUID === networkSlug)?.slug,
    [networks, networkSlug],
  );

  useEffect(() => {
    if (networkSlug && networkSlugForUUID) {
      navigate(
        {
          ...location,
          pathname: location.pathname.replace(networkSlug, networkSlugForUUID),
        },
        {
          replace: true,
        },
      );
    }
  }, [networkSlugForUUID, networkSlug, location, navigate]);

  return null;
}
