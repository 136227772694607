import {
  Box,
  Dock,
  Layout,
  LayoutPage,
  Sidebar,
  SidebarGroup,
  SidebarGroups,
  Skeleton,
  styled,
  VStack,
} from '@meterup/atto';

import { useDockContext } from '../../providers/DockProvider';
import { PaneLoadingFallback } from './PaneLoadingFallback';

const SkeletonDockTarget = styled(Skeleton, {
  '@mobile': {
    width: '100%',
    height: '24px',
    borderRadius: '10px',
  },

  '@tablet': {
    width: '100%',
    height: '28px',
    margin: '4px 0',
    borderRadius: '10px',
  },

  '@desktop': {
    width: '100%',
    height: '20px',
    margin: '4px 0',
    borderRadius: '$8',
  },
});

const SkeletonSearchSkeletons = styled('div', {
  '@tablet': {
    padding: '$4 0',
  },

  '@desktop': {
    padding: '$6 0 $4',
  },
});

const SkeletonProductsSkeletons = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  '@tablet': {
    gap: '$8',
  },

  '@desktop': {
    gap: '$4',
  },
});

const SkeletonDockReposition = styled('div', {
  '@mobile': {
    position: 'absolute',
    inset: '0 $16 0 $56',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
});

const SkeletonSidebarTarget = styled(Skeleton, {
  width: '100%',

  '@notDesktop': {
    height: '$28',
    borderRadius: '10px',
  },

  '@desktop': {
    height: '$20',
    borderRadius: '$8',
  },
});

export function AppLoadingFallback() {
  const { minimized, setMinimized } = useDockContext();
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
      }}
    >
      <Layout>
        <Dock
          minimized={minimized}
          setMinimized={setMinimized}
          search={
            <SkeletonSearchSkeletons>
              <SkeletonDockTarget />
            </SkeletonSearchSkeletons>
          }
          account={
            <SkeletonDockReposition>
              <SkeletonDockTarget />
            </SkeletonDockReposition>
          }
          settings={<SkeletonDockTarget />}
          products={
            <SkeletonProductsSkeletons>
              <SkeletonDockTarget />
              <SkeletonDockTarget />
              <SkeletonDockTarget />
              <SkeletonDockTarget />
            </SkeletonProductsSkeletons>
          }
          resources={
            <>
              <SkeletonDockTarget />
              <SkeletonDockTarget />
            </>
          }
          sidebar={
            <Sidebar>
              <SidebarGroups>
                <SidebarGroup>
                  <Box padding={{ right: 8, bottom: 8, left: 4 }}>
                    <VStack align="center" spacing={16}>
                      <SkeletonSidebarTarget />
                      <SkeletonSidebarTarget />
                      <SkeletonSidebarTarget />
                    </VStack>
                  </Box>
                </SidebarGroup>
                <SidebarGroup>
                  <Box padding={{ all: 8 }}>
                    <VStack align="center" spacing={16}>
                      <SkeletonSidebarTarget />
                      <SkeletonSidebarTarget />
                      <SkeletonSidebarTarget />
                    </VStack>
                  </Box>
                </SidebarGroup>
                <SidebarGroup>
                  <Box padding={{ all: 8 }}>
                    <VStack align="center" spacing={16}>
                      <SkeletonSidebarTarget />
                      <SkeletonSidebarTarget />
                      <SkeletonSidebarTarget />
                    </VStack>
                  </Box>
                </SidebarGroup>
              </SidebarGroups>
            </Sidebar>
          }
        />
        <LayoutPage>
          <PaneLoadingFallback />
        </LayoutPage>
      </Layout>
    </div>
  );
}
