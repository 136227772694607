import { useLocalStorage } from '@meterup/common';
import { type ReactNode, createContext, useContext, useMemo } from 'react';

interface DockContextData {
  minimized: boolean;
  setMinimized: (minimized: boolean) => void;
}

const DockContext = createContext<DockContextData>({
  minimized: false,
  setMinimized: () => {},
});

export function DockProvider({ children }: { children?: ReactNode }) {
  const [minimized, setMinimized] = useLocalStorage('dashboard.dock.minimized', true);

  const value = useMemo(
    () => ({
      minimized,
      setMinimized,
    }),
    [minimized, setMinimized],
  );

  return <DockContext.Provider value={value}>{children}</DockContext.Provider>;
}

export function useDockContext() {
  return useContext(DockContext);
}
