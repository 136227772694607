import { useContext, useEffect } from 'react';

import { useControllerDataFromPathOrNull } from '../hooks/useControllerDataFromPath';
import { useNetworkOrNull } from '../hooks/useNetworkFromPath';
import { DefaultControllerContext } from '../providers/DefaultControllerProvider';
import { DefaultNetworkContext } from '../providers/DefaultNetworkProvider';

export function AutoSaveDefaultNetworkEffect() {
  const { controllerNameFromPath: currentController } = useControllerDataFromPathOrNull();
  const network = useNetworkOrNull();
  const { setDefaultControllerSlug } = useContext(DefaultControllerContext);
  const { setDefaultNetworkUUID } = useContext(DefaultNetworkContext);

  useEffect(() => {
    if (network) {
      setDefaultNetworkUUID(network.UUID);
      setDefaultControllerSlug(null);
    } else if (currentController) {
      setDefaultControllerSlug(currentController);
      setDefaultNetworkUUID(null);
    }
  }, [currentController, network, setDefaultControllerSlug, setDefaultNetworkUUID]);

  return null;
}
