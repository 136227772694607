import type { ExtractRouteParams } from '@meterup/react-router-extensions';
import type { Pathname, Search } from 'history';
import { generatePath } from 'react-router';

import { Nav } from '../nav';

export const makeLink = <T extends string>(path: T, args: ExtractRouteParams<T, string>): string =>
  // @ts-expect-error
  generatePath(path, args);

export type PathAndSearch = {
  pathname: Pathname;
  search: Search;
};

export const makeDrawerLink = <T extends string>(
  currentLocation: PathAndSearch,
  path: T,
  args: ExtractRouteParams<T, string>,
) =>
  Nav.makeTo({
    root: {
      pathname: currentLocation.pathname,
      search: currentLocation.search,
    },
    drawer: makeLink(path, args),
  });

const mergeSearchParams = (a: URLSearchParams, b: Record<string, string | null | undefined>) => {
  const newSearchParams = new URLSearchParams(a);
  Object.entries(b).forEach(([key, value]) => {
    if (value) {
      newSearchParams.set(key, value);
    } else {
      newSearchParams.delete(key);
    }
  });
  return newSearchParams;
};

export const makeParamsLink = (
  currentPathname: string,
  currentSearchParams: string,
  newParams: Record<string, string | null>,
) => {
  const existingParams = new URLSearchParams(currentSearchParams);

  return Nav.makeTo({
    pathname: currentPathname,
    search: mergeSearchParams(existingParams, newParams).toString(),
  });
};
