import type { HasChildren } from '@meterup/atto';
import { isDefined } from '@meterup/common';
import { api } from '@meterup/proto';
import { first } from 'lodash-es';
import React, { createContext, useContext, useMemo } from 'react';

import { useControllersOnCurrentCompany } from '../hooks/useControllersOnCurrentCompany';
import { useLocalStorage } from '../hooks/useLocalStorage';

export const DefaultControllerContext = createContext<{
  defaultControllerSlug: string | null;
  setDefaultControllerSlug: (slug: string | null) => void;
}>(null as any);

function getInstalledPrimaryControllers(controllers: api.ControllerResponse[]) {
  return controllers.filter(
    ({ lifecycle_status }) =>
      lifecycle_status === api.LifecycleStatus.LIFECYCLE_STATUS_INSTALLED_PRIMARY,
  );
}

function pickControllerOrUseFirstAvailable(
  controllers: api.ControllerResponse[],
  preferredController: string | null,
) {
  return controllers.find((d) => d.name === preferredController) ?? first(controllers) ?? null;
}

function pickDefaultController(
  controllers: api.ControllerResponse[],
  preferredController: string | null,
) {
  return (
    pickControllerOrUseFirstAvailable(
      getInstalledPrimaryControllers(controllers),
      preferredController,
    ) ??
    pickControllerOrUseFirstAvailable(controllers, preferredController) ??
    null
  );
}

export function DefaultCurrentControllerProvider({ children }: HasChildren) {
  const [defaultControllerSlug, setDefaultControllerSlug] = useLocalStorage<string | null>(
    'currentController',
    null,
  );

  const controllers = useControllersOnCurrentCompany();

  const firstPermittedDefaultController = pickDefaultController(
    controllers ?? [],
    defaultControllerSlug,
  );

  const value = useMemo(
    () => ({
      defaultControllerSlug: firstPermittedDefaultController?.name ?? null,
      setDefaultControllerSlug,
    }),
    [firstPermittedDefaultController, setDefaultControllerSlug],
  );

  return (
    <DefaultControllerContext.Provider value={value}>{children}</DefaultControllerContext.Provider>
  );
}

export function useDefaultController() {
  const val = useContext(DefaultControllerContext);

  if (!isDefined(val)) {
    throw new Error('useDefaultController must be used within a DefaultControllerContext');
  }

  return val.defaultControllerSlug;
}
