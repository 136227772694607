import { Body, EmptyState, Link, Logo } from '@meterup/atto';
import { mutate, notify } from '@meterup/common';
import { useEffect, useRef, useState } from 'react';

import { axiosInstanceJSON } from '../../api/axiosInstanceJSON';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { styled } from '../../stitches';

export const Meta = () => ({
  path: '/app-login',
  layout: null,
});

const LogoContainer = styled('div', { marginBottom: '$16' });

async function createRetrievalToken() {
  return mutate(async () => axiosInstanceJSON.post('/v1/session-tokens'));
}

export default function AppLogin() {
  const flags = useFeatureFlags();

  const [token, setToken] = useState();
  const appLinkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (!flags['app-login-session-token']) return; // TODO: Move this logic to the routing layer

    const launchApp = async () => {
      try {
        const retrievalToken = (await createRetrievalToken()).data.token;
        setToken(retrievalToken);
        notify('Successfully authenticated!', {
          variant: 'positive',
          icon: 'checkmark',
        });
        appLinkRef?.current?.click();
      } catch (e) {
        notify('Failed to authenticate.', {
          variant: 'negative',
          icon: 'cross',
        });
      }
    };
    launchApp();
  }, [flags]);

  if (!flags['app-login-session-token']) return null; // TODO: Redirect to a 404 page

  return (
    <EmptyState
      heading={
        <LogoContainer>
          <Logo size={40} type="word" />
        </LogoContainer>
      }
      subheading={
        token ? (
          <Body>
            If your application did not open automatically, click{' '}
            <Link ref={appLinkRef} href={`meter://auth?token=${token}`}>
              here
            </Link>{' '}
            to launch.
          </Body>
        ) : null
      }
    />
  );
}
