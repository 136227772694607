import { useIsTrueOperator } from '@meterup/authorization/src/hooks/useIsTrueOperator';
import { useSessionStorage } from '@meterup/common';
import React, { createContext, useContext, useEffect, useMemo } from 'react';

interface SkipFeatureChecksContextValue {
  skipFeatureChecks: boolean;
  setSkipFeatureChecks: (skip: boolean) => void;
}

export const SkipFeatureChecksForOperatorsContext = createContext<SkipFeatureChecksContextValue>({
  skipFeatureChecks: false,
  setSkipFeatureChecks: () => {},
});

export function SkipFeatureChecksForOperatorsProvider({ children }: { children: React.ReactNode }) {
  const [skipFeatureChecks, setSkipFeatureChecks] = useSessionStorage<boolean>(
    'skipFeatureChecks',
    false,
  );
  const isTrueOperator = useIsTrueOperator();
  const atDemoURL = /^demo.meter.(website|com|dev)/.test(window.location.hostname);

  useEffect(() => {
    if (!isTrueOperator) {
      return;
    }

    if (atDemoURL) {
      setSkipFeatureChecks(true);
    }
  }, [setSkipFeatureChecks, isTrueOperator, atDemoURL]);

  const value = useMemo(() => {
    function getVal() {
      if (!isTrueOperator) {
        return false;
      }
      return skipFeatureChecks;
    }

    return {
      skipFeatureChecks: getVal(),
      setSkipFeatureChecks,
    };
  }, [skipFeatureChecks, setSkipFeatureChecks, isTrueOperator]);

  return (
    <SkipFeatureChecksForOperatorsContext.Provider value={value}>
      {children}
    </SkipFeatureChecksForOperatorsContext.Provider>
  );
}

export function useShouldDangerouslySkipFeatureChecksForOperators(): Boolean {
  return useContext(SkipFeatureChecksForOperatorsContext).skipFeatureChecks;
}
