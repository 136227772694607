import { PermissionType, RoleName } from '../gql/graphql';

export type Role = {
  name: RoleName;
  assignPermission: PermissionType | null;
  isCompanySpecific: boolean;
  isNetworkSpecific: boolean;
  label: string;
  variant: 'brand' | 'alternative' | 'neutral';
};

export const ALL_ROLES: Role[] = [
  {
    name: RoleName.Operator,
    assignPermission: PermissionType.PermOperatorAssign,
    isCompanySpecific: false,
    isNetworkSpecific: false,
    label: 'Operator',
    variant: 'brand',
  },
  {
    name: RoleName.Partner,
    assignPermission: PermissionType.PermPartnerAssign,
    isCompanySpecific: true,
    isNetworkSpecific: false,
    label: 'Partner',
    variant: 'brand',
  },
  {
    name: RoleName.CompanyGlobalAdmin,
    assignPermission: PermissionType.PermCompanyAdminAssign,
    isCompanySpecific: true,
    isNetworkSpecific: false,
    label: 'Company admin',
    variant: 'brand',
  },
  {
    name: RoleName.CompanyGlobalAdminReadOnly,
    assignPermission: PermissionType.PermCompanyAdminReadOnlyAssign,
    isCompanySpecific: true,
    isNetworkSpecific: false,
    label: 'Company admin (read-only)',
    variant: 'brand',
  },
  {
    name: RoleName.CompanyNetworkAdmin,
    assignPermission: PermissionType.PermCompanyNetworkAdminAssign,
    isCompanySpecific: true,
    isNetworkSpecific: true,
    label: 'Network admin',
    variant: 'alternative',
  },
  {
    name: RoleName.CompanyNetworkAdminReadOnly,
    assignPermission: PermissionType.PermCompanyNetworkAdminReadOnlyAssign,
    isCompanySpecific: true,
    isNetworkSpecific: true,
    label: 'Network admin (read-only)',
    variant: 'alternative',
  },
  {
    name: RoleName.CompanyStandardUser,
    assignPermission: null, // For now, don't allow assigning the standard user role
    isCompanySpecific: true,
    isNetworkSpecific: true,
    label: 'Standard user',
    variant: 'neutral',
  },
  {
    name: RoleName.CompanyGuest,
    assignPermission: null, // For now, don't allow assigning the guest role
    isCompanySpecific: true,
    isNetworkSpecific: true,
    label: 'Guest',
    variant: 'neutral',
  },
];

export function roleNameToRole(roleName: RoleName): Role | undefined {
  return ALL_ROLES.find((role) => role.name === roleName);
}

function roleSpecificity(role: Role) {
  let specificity = 0;
  if (role.isCompanySpecific) {
    specificity += 1;
  }
  if (role.isNetworkSpecific) {
    specificity += 1;
  }
  return specificity;
}

export function highestRoleName(roleNames: RoleName[]) {
  if (roleNames.length === 0) {
    return null;
  }
  const roles = roleNames.map((roleName) => roleNameToRole(roleName)).filter(Boolean) as Role[];
  const highest = roles.sort((a, b) => roleSpecificity(b) - roleSpecificity(a))[0];
  if (highest) {
    return highest.name;
  }
  return null;
}

export function deletedAtToStatus(deletedAt?: string | null) {
  return deletedAt ? 'Deleted' : 'Active';
}
