import type { PropsWithChildren, ReactNode } from 'react';
import { Icon, space, Text, Tooltip as AttoTooltip } from '@meterup/atto';

import { styled } from '../stitches';

const TooltipHint = styled('span', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$4',
  alignItems: 'center',
});

export function Tooltip({
  asChild,
  align,
  side,
  sideOffset,
  showHint,
  children,
  content,
  toggle,
}: PropsWithChildren<{
  content: ReactNode;
  showHint?: Boolean;
  align?: 'start' | 'end' | 'center';
  side?: 'top' | 'right' | 'bottom' | 'left';
  sideOffset?: number;
  toggle?: boolean;
  asChild?: boolean;
}>) {
  return (
    <AttoTooltip
      asChild={asChild}
      align={align}
      side={side}
      sideOffset={sideOffset}
      contents={content}
      toggle={toggle}
    >
      {showHint ? (
        <TooltipHint>
          <Text>{children}</Text>
          <Icon icon="question" size={space(14)} />
        </TooltipHint>
      ) : (
        children
      )}
    </AttoTooltip>
  );
}
