import { useEffect } from 'react';

import { isiOS } from '../helpers/isiOS';

// We only set this attribute on iOS https://www.a11yproject.com/posts/never-use-maximum-scale/
export function useSetMaximumScale() {
  useEffect(() => {
    if (!isiOS()) return;
    const viewportTag = document.querySelector('meta[name="viewport"]');
    if (!viewportTag) return; // shouldn't happen
    const initialContent = viewportTag.attributes.getNamedItem('content')?.value;

    if (!initialContent) return; // also shouldn't happen

    viewportTag.setAttribute('content', `${initialContent}, maximum-scale=1`);
  }, []);
}
