import { darkThemeSelector, fade, keyframes, palette, styled } from '@meterup/atto';
import { observer } from 'mobx-react-lite';

import { VisualState } from '../core';
import { useCommand } from './Root';

const Box = styled('div');

export const Overlay = observer(() => {
  const { state } = useCommand();

  if ([VisualState.Hidden, VisualState.Hiding].includes(state.ui.visualState)) {
    return null;
  }

  const overlayShow = keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
  });

  // TODO: Add our animations on the overlay + dark mode
  return (
    <Box
      onPointerDown={() => {
        state.ui.update((ui) => {
          // TODO: animations
          // eslint-disable-next-line no-param-reassign
          ui.visualState = VisualState.Hidden;
        });
      }}
      css={{
        position: 'fixed',
        zIndex: 3,
        inset: 0,
        backgroundColor: fade(palette.bgApplicationLight, 0.8),
        boxShadow: `inset 0 120px 120px 120px ${fade(palette.white, 0.12)}`,

        [darkThemeSelector]: {
          backgroundColor: fade(palette.bgNeutralDark, 0.8),
          boxShadow: `inset 0 120px 120px 120px ${fade(palette.black, 0.12)}`,
        },

        '@media (prefers-reduced-motion: no-preference)': {
          animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
        },
      }}
    />
  );
});
