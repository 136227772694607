import type { AxiosResponse } from 'axios';

import type { DisplayableError } from '../errors/isDisplayableError';
import type { HTTPError } from '../errors/isHTTPError';
import type { MeterAPIErrorResponse } from '../errors/isMeterAPIErrorResponse';
import type { WrappedError } from '../errors/isWrappedError';
import {
  ApplicationError,
  NoConnectionError,
  ResourceNotFoundError,
  UnexpectedError,
} from '../errors/displayable_errors';
import {
  BadGatewayError,
  GatewayTimeoutError,
  httpErrorFromStatusCode,
  NotFoundError,
  ServiceUnavailableError,
} from '../errors/http_errors';
import { isAxiosError } from '../errors/isAxiosError';
import { isDisplayableWrappedError } from '../errors/isDisplayableWrappedError';
import { isDefined, isDefinedAndNotEmpty } from '../helpers/isDefined';

function extractErrorMessagesFromResponse(response: AxiosResponse<unknown>) {
  const castData = response.data as MeterAPIErrorResponse;

  if (isDefinedAndNotEmpty(castData.title) && isDefinedAndNotEmpty(castData.detail)) {
    return {
      title: castData.title,
      message: castData.detail,
    };
  }

  if (isDefinedAndNotEmpty(castData.title)) {
    return {
      title: undefined,
      message: castData.title,
    };
  }

  return {
    title: undefined,
    message: undefined,
  };
}

function wrapErrorFromAPICall(
  error: unknown,
): Error & WrappedError & (DisplayableError | HTTPError) {
  if (error instanceof Error && isDisplayableWrappedError(error)) {
    return error;
  }

  if (isAxiosError(error)) {
    const { response } = error;
    if (isDefined(response)) {
      const ErrorClass = httpErrorFromStatusCode(response.status);

      const errorDisplay = extractErrorMessagesFromResponse(response);

      if (ErrorClass) {
        const httpError = ErrorClass.create({
          displayTitle: errorDisplay.title,
          displayMessage: errorDisplay.message,
          originalError: error,
          request: error.request,
          response,
        });

        if (
          // These createHTTPErrorClass types are cursed so this casting is necessary
          (httpError as unknown) instanceof BadGatewayError ||
          (httpError as unknown) instanceof ServiceUnavailableError ||
          (httpError as unknown) instanceof GatewayTimeoutError
        ) {
          return new NoConnectionError(httpError);
        }

        return httpError;
      }

      return new UnexpectedError(
        `Received non-standard status from server: ${response.status} ${response.statusText}`,
        error,
      );
    }

    if (isDefined(error.request)) {
      return new NoConnectionError(error);
    }

    return new ApplicationError(error.message);
  }

  if (error instanceof Error) {
    return new ApplicationError(error.message, error);
  }

  return new ApplicationError('Unknown error', error);
}

export async function makeAPICall<T>(action: () => Promise<T>): Promise<T> {
  try {
    return await action();
  } catch (failure) {
    throw wrapErrorFromAPICall(failure);
  }
}

async function remapNotFoundToDefaultValue<T, U>(
  promise: Promise<T>,
  defaultValue: U,
): Promise<T | U> {
  try {
    return await promise;
  } catch (error) {
    if (
      error instanceof NotFoundError ||
      error instanceof ResourceNotFoundError ||
      (error instanceof ApplicationError &&
        ((error.originalError &&
          (error.originalError instanceof NotFoundError ||
            error.originalError instanceof ResourceNotFoundError)) ||
          (error.cause &&
            (error.cause instanceof NotFoundError ||
              error.cause instanceof ResourceNotFoundError))))
    ) {
      return defaultValue;
    }

    throw error;
  }
}

const remapNotFoundToNull = <T>(promise: Promise<T>): Promise<T | null> =>
  remapNotFoundToDefaultValue(promise, null);

const remapNotFoundToArray = <T>(promise: Promise<T[]>): Promise<T[]> =>
  remapNotFoundToDefaultValue(promise, []);

export const getOne = <T>(action: () => Promise<T>): Promise<T | null> =>
  remapNotFoundToNull(makeAPICall(action));

export const getMany = <T>(action: () => Promise<T[]>): Promise<T[]> =>
  remapNotFoundToArray(makeAPICall(action));

export const mutateVoid = async (action: () => Promise<unknown>): Promise<void> => {
  await makeAPICall(action);
};

export const mutate = async <T>(action: () => Promise<T>): Promise<T> => makeAPICall(action);
