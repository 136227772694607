import { isDefined } from './isDefined';

export function expectDefinedOrThrow<T>(
  value: T | null | undefined,
  error: Error = new Error('Expected value to be defined'),
): asserts value is T {
  if (!isDefined(value)) {
    throw error;
  }
}

export function checkDefinedOrThrow<T>(
  value: T | null | undefined,
  error: Error = new Error('Expected value to be defined'),
): T {
  if (!isDefined(value)) {
    throw error;
  }

  return value;
}

export function assert(
  value: boolean,
  error: Error = new Error('Expected condition to be true'),
): asserts value {
  if (!value) {
    throw error;
  }
}
