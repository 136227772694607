import type { GraphQLError } from 'graphql';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import React from 'react';

import { getGraphQLError } from '../utils';

interface ErrorBoundaryState {
  error?: Error;
  graphQLError?: GraphQLError;
}

export interface FallbackProps extends ErrorBoundaryState {
  error: NonNullable<ErrorBoundaryState['error']>;
  reset: () => void;
}

export interface GraphQLErrorBoundaryProps {
  fallback: React.FC<FallbackProps>;
  children: React.ReactNode;
  resetKey?: React.Key;
}

interface ErrorBoundaryProps extends GraphQLErrorBoundaryProps {
  reset: () => void;
}

/**
 * Note that this doesn't prevent React from logging the error to the console.
 * https://github.com/facebook/react/issues/15069
 */
class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: undefined, graphQLError: undefined };
  }

  componentDidUpdate(prevProps: Readonly<ErrorBoundaryProps>) {
    if (this.state.error && prevProps.resetKey !== this.props.resetKey) {
      this.handleReset();
    }
  }

  static getDerivedStateFromError(error: Error) {
    return { graphQlError: getGraphQLError(error), error };
  }

  handleReset() {
    this.props.reset();
    this.setState({ error: undefined, graphQLError: undefined });
  }

  render() {
    const { error, graphQLError } = this.state;

    if (error) {
      return this.props.fallback({
        error,
        graphQLError,
        reset: this.handleReset,
      });
    }

    return this.props.children;
  }
}

export function GraphQLErrorBoundary(props: GraphQLErrorBoundaryProps) {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => <ErrorBoundary {...props} reset={reset} />}
    </QueryErrorResetBoundary>
  );
}
