import { useLocalStorage } from '@meterup/common';
import React, { createContext, useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useIsTrueOperator } from '../hooks/useIsTrueOperator';

export const DemoModeContext = createContext<{
  isDemoMode: boolean;
  setIsDemoMode: (demoModeVal: boolean) => void;
}>(null as any);

export function DemoModeProvider({ children }: { children: React.ReactNode }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParamDemoModeVal = searchParams.get('demo');
  const [isDemoMode, setIsDemoMode] = useLocalStorage<boolean>('demoMode', false);
  const isTrueOperator = useIsTrueOperator();
  const atDemoURL = /^demo.meter.(website|com|dev)/.test(window.location.hostname);

  const externalSetIsDemoMode = useCallback(
    (value: boolean) => {
      if (searchParams.has('demo')) {
        searchParams.delete('demo');
      }
      setSearchParams(searchParams);
      setIsDemoMode(value);
    },
    [searchParams, setSearchParams, setIsDemoMode],
  );

  useEffect(() => {
    if (!isTrueOperator) {
      return;
    }
    if (atDemoURL) {
      setIsDemoMode(true);
      return;
    }
    if (typeof queryParamDemoModeVal === 'string') {
      if (queryParamDemoModeVal === 'true') {
        setIsDemoMode(true);
      }
      if (queryParamDemoModeVal !== 'true') {
        setIsDemoMode(false);
      }
    }
  }, [queryParamDemoModeVal, setIsDemoMode, isTrueOperator, atDemoURL]);

  const value = useMemo(() => {
    function getVal() {
      if (!isTrueOperator) {
        return false;
      }
      // first check query param to take as precedence
      if (typeof queryParamDemoModeVal === 'string') {
        if (queryParamDemoModeVal === 'true') return true;
        if (queryParamDemoModeVal !== 'true') return false;
      }
      // otherwise get the value from localStorage.
      return isDemoMode;
    }

    return {
      isDemoMode: getVal(),
      setIsDemoMode: externalSetIsDemoMode,
    };
  }, [queryParamDemoModeVal, isDemoMode, externalSetIsDemoMode, isTrueOperator]);

  return <DemoModeContext.Provider value={value}>{children}</DemoModeContext.Provider>;
}
