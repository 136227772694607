import { checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { useContext } from 'react';

import { graphql } from '../gql';
import {
  useCurrentCompanyFromPath,
  useCurrentCompanyFromPathOrNull,
} from '../hooks/useCurrentCompanyFromPath';
import { DefaultCompanyContext } from './DefaultCompanyProvider';

const companyQuery = graphql(`
  query CompanyUUIDQuery($slug: String!) {
    getCompany(slug: $slug) {
      uuid
      __typename
    }
  }
`);

export const useCurrentCompany = () => {
  const currentCompany = useCurrentCompanyFromPath();

  return currentCompany?.company_slug;
};

export const useCurrentCompany2 = () => {
  const slug = useCurrentCompany();

  return checkDefinedOrThrow(
    useGraphQL(companyQuery, { slug }).data?.getCompany,
    new ResourceNotFoundError('Company not found'),
  );
};

export const useDefaultCompany = () => {
  const defaultCompany = useContext(DefaultCompanyContext);

  return defaultCompany.defaultCompany;
};

export const useCurrentCompanyOrDefault = () => {
  const defaultCompany = useContext(DefaultCompanyContext);
  const currentCompany = useCurrentCompanyFromPathOrNull();

  return currentCompany?.company_slug ?? defaultCompany.defaultCompany;
};
