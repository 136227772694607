import type { AllContractsResponse, AxiosAPIError } from '@meterup/connect-api';
import { get } from '@meterup/connect-api/src/axios';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

import { useCurrentCompanyOrDefault } from '../providers/CurrentCompanyProvider';

function makeQueryKey(companyName: string) {
  return ['connections', companyName];
}

export function useCompanyContracts(companyName: string) {
  const queryKey = makeQueryKey(companyName);
  const results = useQuery<unknown, AxiosAPIError, AllContractsResponse>({
    queryKey,
    queryFn: () => get(`v1/companies/${companyName}/connect/connections`),
    // @ts-ignore: 2769
    refetchInterval: false,
    suspense: true,
  });
  return useMemo(() => results.data?.contracts ?? [], [results]);
}

export function useInvalidateCompanyContracts(companyName: string) {
  const queryClient = useQueryClient();
  const queryKey = makeQueryKey(companyName);
  return useCallback(() => queryClient.invalidateQueries(queryKey), [queryClient, queryKey]);
}

export function useCurrentCompanyContracts() {
  const companyName = useCurrentCompanyOrDefault();
  return useCompanyContracts(companyName);
}
