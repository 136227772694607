import type { Location } from 'history';
import type { To } from 'react-router-dom';
import { createRedirectRouteFn } from '@meterup/common';
import { useMemo } from 'react';
import drawerRoutes from 'virtual:pagefiles/drawers';
import pageRoutes from 'virtual:pagefiles/pages';

import { paths, RootConnectPage } from '../constants';
import { useActiveControllerForNetwork } from '../hooks/useActiveControllerForNetwork';
import { type Network, useNetworkOrNull } from '../hooks/useNetworkFromPath';
import { Nav } from '../nav';
import { useDefaultCompany } from '../providers/CurrentCompanyProvider';
import { useDefaultController } from '../providers/DefaultControllerProvider';
import { useDefaultNetwork } from '../providers/DefaultNetworkProvider';
import { useCurrentRoleOrGuest } from '../providers/RoleProvider';
import AppLogin from '../routes/pages/AppLogin.page';
import GuestPage from '../routes/pages/Guest.page';
import NotFoundPage from '../routes/pages/NotFound.page';
import UserBoardCompanyRoot from '../routes/pages/userboard/UserBoardCompanyRoot';
import UserBoardRootRedirect from '../routes/pages/userboard/UserBoardRootRedirect';
import { makeLink } from '../utils/main_and_drawer_navigation';
import { DNSSecurityTab } from './DNSSecurity/utils';
import { VLANDetailsTab } from './NetworkWide/VLANs/utils';

interface RedirectContext {
  companyName: string;
  controllerName: string | null;
  network: Network | null;
}

const extractSearchIfNecessary = (to: To) => {
  if (!!to && typeof to === 'object' && to.pathname) {
    const index = to.pathname.indexOf('?');

    if (index > -1) {
      const { search = '?' } = to;
      return {
        ...to,
        pathname: to.pathname.slice(0, index),
        search: search + to.pathname.slice(index + 1),
      };
    }
  }

  return to;
};

export const defaultMakeTo = (
  regions: Record<string, Location | null | undefined>,
  path: string | null,
  params: Record<string, string>,
) =>
  extractSearchIfNecessary(
    Nav.makeTo({ ...regions, root: extractSearchIfNecessary(path ? makeLink(path, params) : '/') }),
  );

export const defaultDrawerMakeTo = (
  regions: Record<string, Location | null | undefined>,
  path: string | null,
  params: Record<string, string>,
) =>
  extractSearchIfNecessary(
    Nav.makeTo({
      ...regions,
      drawer: path ? extractSearchIfNecessary(makeLink(path, params)) : null,
    }),
  );

const GuestRoutes = () =>
  Nav.useRoutes('root', [
    { path: '*', element: <NotFoundPage /> },
    { path: '/', element: <GuestPage /> },
    { path: 'org/:companyName', element: <GuestPage /> },
    { path: 'org/:companyName/guest', element: <GuestPage /> },
    { path: '/app-login', element: <AppLogin /> },
  ]);
const MemberRoutes = () =>
  Nav.useRoutes('root', [
    { path: '*', element: <NotFoundPage /> },
    { path: '/', element: <UserBoardRootRedirect /> },
    { path: '/org/:companyName', element: <UserBoardCompanyRoot /> },
    { path: '/app-login', element: <AppLogin /> },
  ]);

const redirectFromControllerToNetworkWithExtraParams =
  <R extends Record<string, string>>(extraParams: R) =>
  <T,>(params: T, context: RedirectContext) => {
    if (!context.network) return false;
    return {
      ...context,
      ...params,
      networkSlug: context.network.slug,
      ...extraParams,
    };
  };

const redirectFromControllerToNetwork = redirectFromControllerToNetworkWithExtraParams({});

const redirectWithExtraParams =
  <R extends Record<string, string>>(extraParams: R) =>
  <T,>(params: T, context: RedirectContext) => ({
    ...context,
    ...params,
    ...extraParams,
  });

const AdminRoutes = () => {
  const companyName = useDefaultCompany();
  const controllerName = useDefaultController();
  const network = useNetworkOrNull();
  const { defaultNetwork } = useDefaultNetwork();

  const redirectContext: RedirectContext = useMemo(
    () => ({
      companyName,
      controllerName,
      network: network ?? defaultNetwork,
    }),
    [companyName, controllerName, network, defaultNetwork],
  );

  const regions = Nav.useRegionLocations();

  const createRedirect = useMemo(
    () => createRedirectRouteFn(defaultMakeTo, redirectContext, '/', regions, regions.root),
    [redirectContext, regions],
  );

  const activeNetworkController = useActiveControllerForNetwork(network);
  const initialSerialNumber =
    activeNetworkController?.hardwareDevice?.serialNumber ??
    network?.virtualDevices[0]?.hardwareDevice?.serialNumber;

  return Nav.useRoutes('root', [
    {
      children: [
        createRedirect('/support', paths.pages.SupportPage),
        createRedirect('/clients', paths.pages.LegacyClientsListPage),
        createRedirect('/clients/:macAddress', paths.pages.LegacyClientDetailPage),
        createRedirect(RootConnectPage, paths.pages.ConnectDashboardAllLocationsPage),
        createRedirect('/dns-security', paths.pages.LegacyDNSSecurityPage),
        createRedirect('/events', paths.pages.EventsListPage),
        createRedirect('/guest', paths.pages.GuestPage),
        createRedirect('/internet-wifi', paths.pages.InternetAndWirelessPage),
        createRedirect('/isps', paths.pages.LegacyISPListPage),
        createRedirect(
          '/join/:ssid',
          paths.pages.NetworkTabletJoinInstructionsPage,
          redirectFromControllerToNetwork,
        ),
        createRedirect('/port-forwarding', paths.pages.LegacyPortForwardingRuleListPage),
        createRedirect('/ssids', paths.pages.LegacySSIDListPage),
        createRedirect('/topology', paths.pages.LegacyTopologyPage),
        createRedirect(
          '/org/:companyName/settings/network/configure',
          paths.pages.SettingsOrgNetworksPage,
          redirectFromControllerToNetwork,
        ),

        createRedirect(
          '/switches',
          paths.pages.SwitchListPage,
          redirectFromControllerToNetworkWithExtraParams({ tab: 'list' }),
        ),
        createRedirect('/vlans', paths.pages.LegacyVLANListPage),
        createRedirect('/vpn', paths.pages.LegacyVPNListPage),
        createRedirect(
          '/config',
          paths.pages.DeviceConfigOverridesPage,
          redirectFromControllerToNetworkWithExtraParams({ serialNumber: initialSerialNumber! }),
        ),
        createRedirect(
          '/documents',
          paths.pages.SettingsOrgDocumentsPage,
          redirectFromControllerToNetwork,
        ),
        createRedirect(
          '/org/:companyName/controller/:controllerName/events',
          paths.pages.EventsListPage,
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/switches',
          paths.pages.SwitchListPage,
          redirectWithExtraParams({ tab: 'list' }),
        ),

        createRedirect(
          '/org/:companyName/network/:networkSlug/hardware/access-points',
          paths.pages.AccessPointsListPage,
        ),

        // Dock top-level redirects
        createRedirect('/org/:companyName/hub', paths.pages.HubCardsPage),

        // Redirects for top-level item in sidebar
        createRedirect(paths.pages.InsightsPage, paths.pages.InsightsNetworkPage),
        createRedirect(
          paths.pages.HardwarePage,
          paths.pages.PowerDistributionUnitListPage,
          redirectWithExtraParams({ tab: 'list' }),
        ),
        createRedirect(paths.pages.NetworkWidePage, paths.pages.VLANListPage),
        createRedirect(paths.pages.WirelessPage, paths.pages.SSIDsPage),
        createRedirect(
          paths.pages.FirewallPage,
          paths.pages.RulesPage,
          redirectWithExtraParams({
            tab: 'vlans',
          }),
        ),
        createRedirect(paths.pages.DesignPage, paths.pages.TopologyPage),
        createRedirect(paths.pages.SecureTunnelsPage, paths.pages.ClientVPNPage),

        // Controller based URLs to network based
        createRedirect(
          '/org/:companyName/controller/:controllerName/rack-elevations',
          paths.pages.RackElevationsPage,
          redirectFromControllerToNetwork,
        ),
        createRedirect(
          '/org/:companyName/controller/:controllerName/switches/list',
          paths.pages.LegacySwitchListPage,
          redirectFromControllerToNetwork,
        ),
        createRedirect(
          '/org/:companyName/controller/:controllerName/switches/:tab',
          paths.pages.SwitchListPage,
          redirectFromControllerToNetwork,
        ),
        createRedirect(
          '/org/:companyName/controller/:controllerName/switches/:uuid/:tab',
          paths.pages.SwitchDetailPage,
          redirectFromControllerToNetwork,
        ),

        createRedirect(
          '/org/:companyName/network/:networkSlug/captive-portals',
          paths.pages.CaptivePortalPage,
          redirectWithExtraParams({
            tab: 'configure',
          }),
        ),

        createRedirect(
          '/org/:companyName/network/:networkSlug/config',
          paths.pages.DeviceConfigOverridesPage,
          redirectWithExtraParams({ serialNumber: initialSerialNumber! }),
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/firewall-rules',
          paths.pages.RulesPage,
          redirectWithExtraParams({
            tab: 'vlans',
          }),
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/port-forwarding',
          paths.pages.RulesPage,
          redirectWithExtraParams({
            tab: 'port-forwarding',
          }),
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/insights/clients/:macAddress',
          paths.pages.ClientInsightsPage,
        ),
        // *** START: deprecated hardware locations ***
        // power-distribution-units
        createRedirect(
          '/org/:companyName/network/:networkSlug/power-units/:tab',
          paths.pages.PowerDistributionUnitListPage,
          redirectWithExtraParams({
            tab: 'list',
          }),
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/power-units/:uuid/:tab',
          paths.pages.PowerDistributionUnitDetailPage,
          redirectWithExtraParams({
            tab: 'outlets',
          }),
        ),
        // switches
        createRedirect(
          '/org/:companyName/network/:networkSlug/switches/:tab',
          paths.pages.SwitchListPage,
          redirectWithExtraParams({
            tab: 'list',
          }),
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/switches/:uuid/:tab',
          paths.pages.SwitchDetailPage,
          redirectWithExtraParams({
            tab: 'insights',
          }),
        ),
        // access-points
        createRedirect(
          '/org/:companyName/network/:networkSlug/access-points/:uuid/:tab',
          paths.pages.AccessPointPage,
          redirectWithExtraParams({
            tab: 'insights',
          }),
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/access-points/list',
          paths.pages.AccessPointsListPage,
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/access-points/:uuid/:tab',
          paths.drawers.AccessPointDrawerPage,
          redirectWithExtraParams({
            tab: 'access-point',
          }),
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/access-points/:id/edit',
          paths.drawers.AccessPointEditDrawerPage,
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/access-points/batch-edit/:accessPointUUIDs',
          paths.drawers.AccessPointBatchEditPage,
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/access-points/create',
          paths.drawers.AccessPointCreatePage,
        ),
        // security-appliances
        createRedirect(
          '/org/:companyName/network/:networkSlug/security-appliances/:uuid/:tab',
          paths.pages.SecurityApplianceDetailPage,
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/hardware/security-appliances/:uuid',
          paths.pages.SecurityApplianceDetailPage,
          redirectWithExtraParams({
            tab: 'insights',
          }),
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/hardware/security-appliances/:uuid/host-monitoring',
          paths.pages.HostMonitoringPage,
        ),
        // *** END: deprecated hardware locations ***

        // Settings
        // // Organization
        // // // Network / Notifications -> Org / Notifications
        createRedirect(
          '/org/:companyName/settings/network/:networkSlug/notifications',
          paths.pages.SettingsOrgNotificationsPage,
        ),
        // // // Network / Notifications / Alerts -> Org / Notifications / Alerts
        createRedirect(
          '/org/:companyName/settings/network/:networkSlug/notifications/alerts',
          paths.pages.SettingsOrgNotificationsAlertsPage,
        ),
        // // // Network / Notifications / Reports -> Org / Notifications / Reports
        createRedirect(
          '/org/:companyName/settings/network/:networkSlug/notifications/reports',
          paths.pages.SettingsOrgNotificationsReportsPage,
        ),
        // // // Network / General -> Org / Networks
        createRedirect(
          '/org/:companyName/settings/network/:networkSlug/general',
          paths.pages.SettingsOrgNetworksPage,
        ),

        // Network-wide
        // // ISPs
        // // // ISP insights -> ISPs list
        createRedirect(
          '/org/:companyName/network/:networkSlug/network-wide/isps/:uuid/insights',
          paths.pages.ISPsPage,
        ),
        // // // ISP events -> ISPs list
        createRedirect(
          '/org/:companyName/network/:networkSlug/network-wide/isps/:uuid/events',
          paths.pages.ISPsPage,
        ),
        // // VLANs
        // // // VLAN details -> VLAN insights
        createRedirect(
          '/org/:companyName/network/:networkSlug/network-wide/vlans/:uuid/details',
          paths.pages.VLANDetailsPage,
          redirectWithExtraParams({
            tab: VLANDetailsTab.Insights,
          }),
        ),

        // Firewall
        // // DNS security
        // // // DNS security create rule -> DNS security add rule
        createRedirect(
          '/org/:companyName/network/:networkSlug/dns-security/create',
          paths.drawers.DNSSecurityRuleAddPage,
          redirectWithExtraParams({
            tab: DNSSecurityTab.VLANs,
          }),
        ),
        // // // DNS security rule edit -> DNS security rule edit
        createRedirect(
          '/org/:companyName/network/:networkSlug/dns-security/:ruleUUID',
          paths.drawers.DNSSecurityRuleEditPage,
          redirectWithExtraParams({
            tab: DNSSecurityTab.VLANs,
          }),
        ),
        // // Rate limiting
        // // // Rate limiting create rule -> Rate limiting add rule
        createRedirect(
          '/org/:companyName/network/:networkSlug/firewall/rate-limiting/create',
          paths.drawers.RateLimitingRuleAddPage,
        ),

        // Secure tunnels
        // // Create IPSec tunnel -> Add IPSec tunnel
        createRedirect(
          '/org/:companyName/network/:networkSlug/ipsec-tunnels/create',
          paths.drawers.AddIPSecTunnelDrawerPage,
        ),
        // // IPSec tunnel drawer -> IPSec tunnel drawer
        createRedirect(
          '/org/:companyName/network/:networkSlug/ipsec-tunnels/:IPSecUUID',
          paths.drawers.IPSecTunnelDrawerPage,
        ),
      ],
    },
    {
      children: pageRoutes,
    },
  ]);
};

export const MainRoutes = () => {
  const role = useCurrentRoleOrGuest();

  if (role === 'admin') return AdminRoutes();
  if (role === 'member') return MemberRoutes();

  return GuestRoutes();
};

export const DrawerRoutes = () => {
  const companyName = useDefaultCompany();
  const controllerName = useDefaultController();
  const network = useNetworkOrNull();

  const redirectContext: RedirectContext = useMemo(
    () => ({
      companyName,
      controllerName,
      network,
    }),
    [companyName, controllerName, network],
  );

  const regions = Nav.useRegionLocations();
  const drawerLocation = Nav.useRegionLocation('drawer');

  const createRedirect = useMemo(
    () =>
      createRedirectRouteFn(defaultDrawerMakeTo, redirectContext, null, regions, drawerLocation),
    [redirectContext, regions, drawerLocation],
  );

  return Nav.useRoutes('drawer', [
    {
      children: [
        createRedirect('/clients/:macAddress', paths.drawers.LegacyClientSummaryPage),
        createRedirect('/clients/:macAddress/rename', paths.drawers.LegacyClientRenamePage),
        createRedirect('/clients/add', paths.drawers.LegacyAddClientPage),
        createRedirect('/ssids/:id/edit', paths.drawers.LegacySSIDEditPage),
        createRedirect('/ssids/:id/remove', paths.drawers.LegacySSIDRemovePage),
        createRedirect('/ssids/create', paths.drawers.LegacySSIDCreatePage),

        // Controller based URLs to network based
        createRedirect(
          '/org/:companyName/controller/:controllerName/rack-elevations/:elevationUUID/attached-device/:deviceUUID',
          paths.drawers.EditRackMountPage,
          redirectFromControllerToNetwork,
        ),
        createRedirect(
          '/org/:companyName/controller/:controllerName/rack-elevations/:elevationUUID/attached-device/add',
          paths.drawers.AttachDeviceDrawerPage,
          redirectFromControllerToNetwork,
        ),
        createRedirect(
          '/org/:companyName/controller/:controllerName/rack-elevations/:elevationUUID/notes/:noteIndexStart',
          paths.drawers.EditRackNotePage,
          redirectFromControllerToNetwork,
        ),
        createRedirect(
          '/org/:companyName/controller/:controllerName/rack-elevations/:elevationUUID/notes/add',
          paths.drawers.AddRackNotePage,
          redirectFromControllerToNetwork,
        ),
        createRedirect(
          '/org/:companyName/controller/:controllerName/rack-elevations/attach-hardware',
          paths.drawers.AssignHardwareToNetworkPage,
          redirectFromControllerToNetwork,
        ),
        createRedirect(
          '/org/:companyName/controller/:controllerName/switches/:virtualDeviceUUID/ports/:phyInterfaceUUID',
          paths.drawers.SwitchPortDetailPage,
          redirectFromControllerToNetwork,
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/switches/:virtualDeviceUUID/ports/:phyInterfaceUUID',
          paths.drawers.SwitchPortDetailPage,
          redirectFromControllerToNetwork,
        ),
        createRedirect(
          '/org/:companyName/controller/:controllerName/switches/vlans/:uuid',
          paths.drawers.VLANEditPage,
          redirectFromControllerToNetwork,
        ),
        createRedirect(
          '/org/:companyName/controller/:controllerName/switches/vlans/create',
          paths.drawers.VLANsAddPage,
          redirectFromControllerToNetwork,
        ),
        createRedirect(
          '/org/:companyName/network/:networkSlug/port-forwarding/:ruleUUID',
          paths.drawers.EditPortForwardingRulePage,
        ),
      ],
    },
    {
      children: drawerRoutes,
    },
  ]);
};
