import type { HasChildren } from '@meterup/atto';
import { isDefined } from '@meterup/common';
import { createContext, useContext, useMemo } from 'react';

import type { Network } from '../hooks/useNetworksForCompany';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { getDefaultNetwork, useNetworksForCompany } from '../hooks/useNetworksForCompany';
import { useCurrentCompanyOrDefault } from './CurrentCompanyProvider';

export const DefaultNetworkContext = createContext<{
  defaultNetwork: Network | null;
  defaultNetworkUUID: string | null;
  setDefaultNetworkUUID: (uuid: string | null) => void;
}>(null as any);

export function DefaultCurrentNetworkProvider({ children }: HasChildren) {
  const [defaultNetworkUUID, setDefaultNetworkUUID] = useLocalStorage<string | null>(
    'currentNetwork',
    null,
  );

  const companyName = useCurrentCompanyOrDefault();
  const networks = useNetworksForCompany(companyName);

  let defaultNetwork: Network | undefined;
  if (defaultNetworkUUID) {
    defaultNetwork = networks.find((n) => n.UUID === defaultNetworkUUID);
  }

  if (!defaultNetwork) {
    defaultNetwork = getDefaultNetwork(networks) ?? undefined;
  }

  const value = useMemo(
    () => ({
      defaultNetwork: defaultNetwork ?? null,
      defaultNetworkUUID,
      setDefaultNetworkUUID,
    }),
    [defaultNetwork, defaultNetworkUUID, setDefaultNetworkUUID],
  );

  return <DefaultNetworkContext.Provider value={value}>{children}</DefaultNetworkContext.Provider>;
}

export function useDefaultNetwork() {
  const val = useContext(DefaultNetworkContext);

  if (!isDefined(val)) {
    throw new Error('useDefaultNetwork must be used within a DefaultNetworkContext');
  }

  return val;
}
