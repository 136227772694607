import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import { useLocalStorage } from '../hooks/useLocalStorage';

export type Theme = 'dark' | 'light' | 'system';

interface ThemeData {
  theme: Theme;
  setTheme: (val: Theme) => void;
  dark: boolean;
}
export const ThemeContext = React.createContext<ThemeData>({} as any);

export default function ThemeProvider({ children }: { children?: React.ReactNode }) {
  const calculateDarkForTheme = useCallback(
    (t: string) =>
      t === 'system' ? window.matchMedia('(prefers-color-scheme: dark)').matches : t === 'dark',
    [],
  );
  const [theme, setTheme] = useLocalStorage<Theme>('theme', 'system');
  const [dark, setDark] = useState(calculateDarkForTheme(theme));

  useEffect(() => {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
      if (theme === 'system') setDark(e.matches);
    });

    if (theme === 'system') {
      setDark(window.matchMedia('(prefers-color-scheme: dark)').matches);
    } else {
      setDark(theme === 'dark');
    }

    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {});
    };
  }, [theme, setDark]);

  useLayoutEffect(() => {
    if (dark && !document.body.classList.contains('m-dark')) {
      document.body.classList.add('m-dark');
    } else if (!dark) {
      document.body.classList.remove('m-dark');
    }
  }, [dark]);

  const providerValue = useMemo(() => ({ theme, setTheme, dark }), [theme, setTheme, dark]);
  return <ThemeContext.Provider value={providerValue}>{children}</ThemeContext.Provider>;
}

export function useTheme() {
  return useContext(ThemeContext);
}
