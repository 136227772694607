import { Pane, PaneContent, PaneFooter, PaneHeader, SkeletonGroup } from '@meterup/atto';
import React from 'react';

export function PaneHeaderFallback() {
  return (
    <PaneHeader
      heading={
        <SkeletonGroup
          direction="row"
          gutter="none"
          skeletons={[
            {
              height: 20,
              width: 20,
              radius: 8,
            },
            {
              height: 20,
              width: 200,
              radius: 8,
            },
          ]}
        />
      }
    />
  );
}

export function PaneContentFallback() {
  return (
    <PaneContent gutter="all">
      <SkeletonGroup
        gutter="none"
        skeletons={Array.from({ length: 5 }).map(() => ({
          height: 100,
          width: '100%',
          radius: 12,
        }))}
      />
    </PaneContent>
  );
}

export function PaneFooterFallback() {
  return (
    <PaneFooter
      actions={
        <SkeletonGroup
          direction="row"
          gutter="none"
          skeletons={[
            {
              height: 20,
              width: 100,
              radius: 8,
            },
            {
              height: 20,
              width: 100,
              radius: 8,
            },
          ]}
        />
      }
    />
  );
}

export function PaneLoadingFallback() {
  return (
    <Pane>
      <PaneHeaderFallback />
      <PaneContentFallback />
      <PaneFooterFallback />
    </Pane>
  );
}
