import { useMemo } from 'react';

import { HasRole } from '../core/typesHelpers';
import { RoleName } from '../gql/graphql';
import { useAuthorization } from '../providers/AuthorizationProvider';

// Should not be used in favor of useIsOperator.
export function useIsTrueOperator() {
  const authz = useAuthorization();
  return useMemo(
    () =>
      authz.check(
        HasRole({
          roleName: RoleName.Operator,
        }),
      ),
    [authz],
  );
}
