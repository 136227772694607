import { useMemo } from 'react';

import type { DemoMode } from '../types/types';
import { useIsDemoMode } from './useIsDemoMode';
import { useIsTrueOperator } from './useIsTrueOperator';

/**
 * Returns a boolean indicating if the user is an Operator.
 * @param {Object} options
 * @param {boolean} options.respectDemoMode If true, the isDemoMode value of the DemoModeProvider will take precedence
 * over the role Operator.
 */
export function useIsOperator({ respectDemoMode }: DemoMode = { respectDemoMode: true }) {
  const isDemoMode = useIsDemoMode();
  const isOperator = useIsTrueOperator();
  return useMemo(() => {
    if (isDemoMode && respectDemoMode) return false;
    return isOperator;
  }, [isDemoMode, isOperator, respectDemoMode]);
}
