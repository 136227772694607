export const CaptivePortalDefaults = {
  displaySettings: {
    backgroundColor: '#FFFFFF',
    fontColor: '#343647',
    callToActionColor: '#343647',
  },
  callToAction: 'Connect to the Wi-Fi',
  logoImageURL:
    'https://assets-global.website-files.com/62700af41da70b605419982b/632a2b4918bc5b0615fe6ade_Meter%20Logo.svg',
  isExternal: false,
  isEnabled: true,
  externalPortalURL: null,
} as const;
