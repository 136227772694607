export interface DisplayableError extends Error {
  readonly isDisplayableError: true;
  displayTitle: string;
  displayMessage?: string;
}

export const isDisplayableError = (error: any): error is DisplayableError =>
  typeof error === 'object' &&
  error !== null &&
  'isDisplayableError' in error &&
  (error as DisplayableError).isDisplayableError;
