import { EmptyState, Logo } from '@meterup/atto';
import React from 'react';

import { AccountDropdown } from '../../../components/Navigation/AccountDropdown';
import { styled } from '../../../stitches';

const LogoContainer = styled('div', { marginBottom: '$16' });
const AccountsDropdownContainer = styled('div', {
  margin: '0 auto',
  maxWidth: '360px',
});

export default function UserBoardCompanyRoot() {
  return (
    <EmptyState
      heading={
        <LogoContainer>
          <Logo size={40} type="word" />
        </LogoContainer>
      }
      action={
        <AccountsDropdownContainer>
          <AccountDropdown />
        </AccountsDropdownContainer>
      }
    />
  );
}
