import type { HTTPError } from './isHTTPError';

interface HTTPErrorCreateParams {
  displayTitle?: string;
  displayMessage?: string;
  originalError?: unknown;
  request?: unknown;
  response?: unknown;
}

export interface HTTPErrorConstructor {
  new (
    displayTitle?: string,
    displayMessage?: string,
    originalError?: unknown,
    request?: unknown,
    response?: unknown,
  ): HTTPError;

  create(props: HTTPErrorCreateParams): HTTPError;
}

export function createHTTPErrorClass(
  name: string,
  statusCode: number,
  defaultDisplayTitle: string,
  defaultDisplayMessage?: string,
): HTTPErrorConstructor {
  return class extends Error implements HTTPError {
    readonly isHTTPError = true;

    readonly statusCode = statusCode;

    readonly isClientError = statusCode >= 400 && statusCode < 500;

    readonly isServerError = statusCode >= 500 && statusCode < 600;

    static displayName = name;

    constructor(
      readonly displayTitle: string = defaultDisplayTitle,
      readonly displayMessage: string | undefined = defaultDisplayMessage,
      readonly originalError?: unknown,
      readonly request?: unknown,
      readonly response?: unknown,
    ) {
      super(displayTitle);
      this.name = name;
      if (originalError) {
        this.cause = originalError;
      }
    }

    static create(props: HTTPErrorCreateParams) {
      return new this(
        props.displayTitle ?? defaultDisplayTitle,
        props.displayMessage ?? defaultDisplayMessage,
        props.originalError,
        props.request,
        props.response,
      );
    }
  };
}
