import { Button, EmptyState, Layout, LayoutPage, Pane, PaneContent } from '@meterup/atto';
import React from 'react';

import { ReactRouterLink } from '../../components/ReactRouterLink';
import { paths } from '../../constants';
import { makeLink } from '../../utils/main_and_drawer_navigation';

export const Meta = () => ({
  path: '*',
  layout: null,
});

export function NotFoundPage() {
  return (
    <Layout>
      <LayoutPage>
        <Pane>
          <PaneContent>
            <EmptyState
              icon="attention"
              heading="Page not found"
              action={
                <Button
                  as={ReactRouterLink}
                  to={makeLink(paths.pages.RootPage, {})}
                  arrangement="leading-icon"
                  icon="home"
                >
                  Back to the Meter Dashboard
                </Button>
              }
            />
          </PaneContent>
        </Pane>
      </LayoutPage>
    </Layout>
  );
}

export default NotFoundPage;
