import React from 'react';
import { Navigate } from 'react-router';

import { useDefaultCompany } from '../../../providers/CurrentCompanyProvider';
import { makeLink } from '../../../utils/main_and_drawer_navigation';

export default function UserBoardRootRedirect() {
  const companyName = useDefaultCompany();

  return <Navigate to={makeLink('/org/:companyName', { companyName })} replace />;
}
