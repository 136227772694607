import type { Location } from 'history';
import { isDefined, isDefinedAndNotEmpty, ResourceNotFoundError } from '@meterup/common';
import { matchPath, useLocation } from 'react-router';

import { paths } from '../constants';
import { useControllersOnCurrentCompanyFromPath } from './useControllersOnCurrentCompany';

export function matchPathWithCompanyAndControllerParams(location: Location) {
  return matchPath(
    {
      path: paths.pages.OverviewPage,
      end: false,
    },
    location.pathname,
  );
}

export const useControllerDataFromPathOrNull = () => {
  const location = useLocation();
  const controllers = useControllersOnCurrentCompanyFromPath();

  const controllerNameFromPath =
    matchPathWithCompanyAndControllerParams(location)?.params.controllerName ?? null;

  const currentController = controllers?.find((c) => c.name === controllerNameFromPath) ?? null;

  return { currentController, controllerNameFromPath };
};

export const useControllerDataFromPath = () => {
  const value = useControllerDataFromPathOrNull();

  if (isDefinedAndNotEmpty(value.controllerNameFromPath) && !isDefined(value.currentController)) {
    throw new ResourceNotFoundError('Security appliance not found');
  }

  return value;
};
