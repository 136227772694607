import { getControllerVersion, isDefined } from '@meterup/common';
import { useContext } from 'react';

import { useControllerDataFromPathOrNull } from '../../hooks/useControllerDataFromPath';
import { useControllersOnCurrentCompanyFromPath } from '../../hooks/useControllersOnCurrentCompany';
import { DefaultControllerContext } from '../DefaultControllerProvider';

export function useCurrentOrDefaultController() {
  const defaultControllerContext = useContext(DefaultControllerContext);
  const { currentController } = useControllerDataFromPathOrNull();
  if (isDefined(currentController)) {
    return currentController.name;
  }
  if (isDefined(defaultControllerContext)) {
    return defaultControllerContext.defaultControllerSlug;
  }
  return null;
}

export function useCurrentOrDefaultControllerData() {
  const controllerName = useCurrentOrDefaultController();
  const controllers = useControllersOnCurrentCompanyFromPath();
  return controllers?.find((c) => c.name === controllerName) ?? null;
}

export function useCurrentOrDefaultControllerVersion() {
  const controllerData = useCurrentOrDefaultControllerData();
  if (!controllerData) {
    return null;
  }

  return getControllerVersion(controllerData);
}
