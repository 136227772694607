import { ViewportProvider } from '@meterup/atto';
import { observer } from 'mobx-react-lite';
import React from 'react';

import type { Node } from '../core';
import { State } from '../core/state';

interface Props {
  children: React.ReactNode;
  createRoot?: (nodeFactory: State['nodeFactory']) => Node | Node[];
}

interface IRootContext {
  state: State;
}

const RootContext = React.createContext({} as IRootContext);

export const Root = observer((props: Props) => {
  const rCreateRoot = React.useRef(props.createRoot);
  rCreateRoot.current = props.createRoot;

  const rState = React.useRef(new State(rCreateRoot.current));

  React.useEffect(() => {
    rState.current = new State(rCreateRoot.current);
    return () => {
      if (rState.current) {
        rState.current.dispose();
      }
    };
  }, []);

  const value = React.useMemo(
    () => ({
      state: rState.current,
    }),
    [],
  );

  return (
    <RootContext.Provider value={value}>
      <ViewportProvider>{props.children}</ViewportProvider>
    </RootContext.Provider>
  );
});

export function useCommand() {
  const context = React.useContext(RootContext);
  if (!context) {
    throw new Error(`useCommand must be used within a valid Command.Root`);
  }
  return context;
}
