import React, { useMemo } from 'react';

import type { AuthorizationState } from '../core/AuthorizationState';
import { AuthorizationStateImpl } from '../core/AuthorizationState';
import { useRoles } from '../hooks/useRole';

export const AuthorizationContext = React.createContext<AuthorizationState>(
  new AuthorizationStateImpl(),
);

/**
 * @deprecated Use `usePermissions` instead. Do not check the user roles directly.
 */
export function useAuthorization() {
  return React.useContext(AuthorizationContext);
}

type AuthorizationProviderProps = {
  children: React.ReactNode;
  companySlug?: string;
};

export function AuthorizationProvider({ companySlug, children }: AuthorizationProviderProps) {
  const params = useMemo(() => {
    if (companySlug) {
      return { companySlug };
    }
    return undefined;
  }, [companySlug]);
  const rolesResult = useRoles(params);

  const state = useMemo(
    () => new AuthorizationStateImpl(rolesResult.data?.roles),
    [rolesResult.data?.roles],
  );

  return <AuthorizationContext.Provider value={state}>{children}</AuthorizationContext.Provider>;
}
