import { colors, darkThemeSelector, styled } from '../stitches';

const HeaderContainer = styled('div', {
  gridArea: 'shell-header',
  display: 'flex',
  flexDirection: 'column',
  strokeAll: colors.strokeApplicationLight,
  [darkThemeSelector]: {
    outlineColor: colors.strokeApplicationDark,
  },
});

const HeaderContent = styled('div', {
  hStack: '$12',
  alignItems: 'stretch',
  minHeight: 48,
  padding: '0 $16',
  overflow: 'auto',
});

const HeaderMetadata = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: '$8',
  '& > a': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '$8',
  },
});

export const DetailPageLayouts = {
  HeaderContainer,
  HeaderContent,
  HeaderMetadata,
};
