import type { ExtractRouteParams } from '@meterup/react-router-extensions';
import type { Location, To } from 'history';
import type { RouteObject } from 'react-router-dom';
import React from 'react';
import { matchPath, Navigate } from 'react-router-dom';

import { isDefined } from '../helpers/isDefined';

export type MapParamsFn<
  FromValue extends string,
  ToValue extends string,
  Context extends Record<string, any> = {},
> = (
  params: ExtractRouteParams<FromValue, string>,
  context: Context,
) => ExtractRouteParams<ToValue, string> | null | false;

export type MakeToFn = (
  regions: Record<string, Location | null | undefined>,
  path: string | null,
  params: Record<string, string>,
) => To | string;

export interface RedirectEntry<
  FromValue extends string,
  ToValue extends string,
  Context extends Record<string, any> = {},
> {
  from: FromValue;
  to: ToValue;
  mapParams: MapParamsFn<FromValue, ToValue, Context>;
  makeTo: MakeToFn;
}

type NoUndefinedField<T> = { [P in keyof T]-?: NoUndefinedField<NonNullable<T[P]>> };

type DefaultMappedParams<
  FromValue extends string,
  Context extends Record<string, any>,
> = ExtractRouteParams<FromValue, string> & NoUndefinedField<Context>;

// TRICKY: This type alias is used to determine if the `mapParams` function is
// optional or not. The function is optional when every param in the ToValue has
// a corresponding param from context or from the extracted params in the
// FromValue. The type is an array so that we can use the spread operator in the
// function signature to mark it optional. It's pretty whack.
export type RedirectOptions<
  ToValue extends string,
  FromValue extends string,
  Context extends Record<string, any>,
> =
  DefaultMappedParams<FromValue, Context> extends ExtractRouteParams<ToValue, string> | null
    ? [MapParamsFn<FromValue, ToValue, Context>?]
    : [MapParamsFn<FromValue, ToValue, Context>];

export function RedirectElement<
  FromValue extends string,
  ToValue extends string,
  Context extends object = {},
>({
  from,
  to,
  mapParams,
  makeTo,
  context,
  regionLocations,
  referenceLocation,
  fallbackRoute = '/',
}: RedirectEntry<FromValue, ToValue, Context> & {
  context: Context;
  fallbackRoute?: string | null;
  regionLocations: Record<string, Location | null | undefined>;
  referenceLocation: Location;
}) {
  const fromMatch = matchPath({ path: from, end: true }, referenceLocation.pathname);

  if (isDefined(fromMatch)) {
    const toParams =
      mapParams(fromMatch.params as unknown as ExtractRouteParams<FromValue, string>, context) ??
      {};

    let finalRedirectPath: To;
    try {
      finalRedirectPath = makeTo(regionLocations, to, toParams as Record<string, string>);
    } catch {
      finalRedirectPath = makeTo(regionLocations, fallbackRoute, {});
    }

    return <Navigate replace to={finalRedirectPath} />;
  }

  return <Navigate replace to={makeTo(regionLocations, fallbackRoute, {})} />;
}

export const createRedirectRouteFn = <Context extends object>(
  makeTo: MakeToFn,
  context: Context,
  fallbackRoute: string | null,
  regions: Record<string, Location | null | undefined>,
  referenceLocation: Location | null,
) => {
  const defaultMapParams = <Params extends object = {}>(p: Params, ctx: Context) =>
    ({ ...p, ...ctx }) as Params & Context;

  return <FromValue extends string, ToValue extends string>(
    from: FromValue,
    to: ToValue,
    ...mapParams: RedirectOptions<ToValue, FromValue, Context>
  ): RouteObject => {
    const mapParamsFn = mapParams[0];

    return {
      path: from,
      element: referenceLocation ? (
        <RedirectElement
          from={from}
          to={to}
          mapParams={mapParamsFn ?? (defaultMapParams as MapParamsFn<FromValue, ToValue, Context>)}
          fallbackRoute={fallbackRoute}
          makeTo={makeTo}
          context={context}
          regionLocations={regions}
          referenceLocation={referenceLocation}
        />
      ) : null,
    };
  };
};
