import '@meterup/atto/src/styles/fonts.css';
// Need this to fix console error regeneratorRuntime is not defined.
import './sentry';

import { datadogRum } from '@datadog/browser-rum';
import { enableMapSet } from 'immer';
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './components/App';
import { init as initGoogleMaps } from './googleMaps';
import { initializePosthog } from './utils/initializePosthog';

initGoogleMaps();
initializePosthog();
enableMapSet();

const realm = import.meta.env.REALM;
const allowedTracingUrls: string[] = [];

if (realm === 'local') {
  allowedTracingUrls.push('https://portal.meter.website:6894');
} else if (realm === 'dev') {
  allowedTracingUrls.push('https://portal.meter.dev');
} else if (realm === 'prod') {
  allowedTracingUrls.push('https://portal.meter.com');
}

if (realm === 'dev' || realm === 'prod') {
  datadogRum.init({
    applicationId: 'f6093102-0c44-4d5e-8755-f047dfda2ac7',
    clientToken: 'pub1cb8b3d95b71d8f41d02a138c8b8cd58',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: 'dashboard',
    env: realm,
    version: import.meta.env.VERCEL_GIT_COMMIT_SHA,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    enableExperimentalFeatures: ['feature_flags', 'writable_resource_graphql'],
    // Tracing won't work until we allow the `traceparent` header from portal.
    // allowedTracingUrls,
  });
}

const root = createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
