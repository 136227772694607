/* eslint-disable max-classes-per-file */
import type { DisplayableError } from './isDisplayableError';
import type { WrappedError } from './isWrappedError';
import { BadRequestError, NotFoundError } from './http_errors';

export class ResourceNotFoundError extends NotFoundError implements DisplayableError {
  readonly isDisplayableError = true;

  // This used to say "Resource not found", but we display this and "Resource" is usually
  // not what we want to say.
  readonly displayTitle = 'Not found';

  constructor(
    readonly displayMessage: string,
    readonly originalError?: unknown,
  ) {
    super(displayMessage);
    this.name = 'ResourceNotFoundError';
    this.cause = originalError;
  }
}

export class ClientBadRequestError extends BadRequestError implements DisplayableError {
  readonly isDisplayableError = true;

  readonly displayTitle = 'Invalid request';

  constructor(
    readonly displayMessage: string,
    readonly originalError?: unknown,
  ) {
    super(displayMessage);
    this.name = 'ClientBadRequestError';
    this.cause = originalError;
  }
}

export class MetricsLoadError extends Error implements DisplayableError {
  readonly isDisplayableError = true;

  readonly displayTitle = 'Unable to load metrics';

  constructor(
    readonly displayMessage: string,
    readonly originalError?: unknown,
  ) {
    super(displayMessage);
    this.name = 'MetricsLoadError';
    this.cause = originalError;
  }
}

export class UnexpectedError extends Error implements WrappedError {
  readonly isDisplayableError = true;

  readonly displayTitle = 'Unexpected error';

  constructor(
    readonly displayMessage: string,
    readonly originalError?: unknown,
  ) {
    super(displayMessage);
    this.name = 'UnexpectedError';
    this.cause = originalError;
  }
}

export class ApplicationError extends Error implements DisplayableError, WrappedError {
  readonly isDisplayableError = true;

  readonly displayTitle = 'Application error';

  constructor(
    readonly displayMessage: string,
    readonly originalError?: unknown,
  ) {
    super(displayMessage);
    this.name = 'ApplicationError';
    this.cause = originalError;
  }
}

export class ControllerRequestFailedError extends Error implements DisplayableError, WrappedError {
  readonly isDisplayableError = true;

  readonly displayTitle = 'Controller request failed';

  readonly displayMessage: string;

  constructor(
    readonly controllerName: string,
    readonly requestName: string,
    readonly originalError?: unknown,
  ) {
    super('Controller request failed');
    this.name = 'ControllerRequestFailedError';
    this.displayMessage = `The controller request ${this.requestName} for controller ${this.controllerName} failed. The controller may be offline.`;
    this.cause = originalError;
  }
}

export class NoConnectionError extends Error implements DisplayableError, WrappedError {
  readonly isDisplayableError = true;

  readonly displayTitle = 'No connection to server';

  readonly displayMessage = 'Request was sent but no response was received from server.';

  constructor(readonly originalError?: unknown) {
    super('Request was sent but no response was received from server');
    this.name = 'NoConnectionError';
    this.cause = originalError;
  }
}
