import { useDefaultController } from '../providers/DefaultControllerProvider';
import { useControllerDataFromPathOrNull } from './useControllerDataFromPath';

export const useCurrentControllerOrNull = (fallbackToDefault = true) => {
  const controllerFromPath = useControllerDataFromPathOrNull().currentController;
  const defaultController = useDefaultController();
  const fallbackController = fallbackToDefault ? defaultController : null;

  return controllerFromPath?.name ?? fallbackController ?? null;
};
