import React from 'react';

const PagefilesImport0 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/App.layout.tsx"));
const PagefilesImport1 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/AppLogin.page.tsx"));
const PagefilesImport2 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/Command.layout.tsx"));
const PagefilesImport3 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/Command.page.tsx"));
const PagefilesImport4 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/CompanyRoot.page.tsx"));
const PagefilesImport5 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/Guest.page.tsx"));
const PagefilesImport6 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/NetworkRoot.page.tsx"));
const PagefilesImport7 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/NotFound.page.tsx"));
const PagefilesImport8 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/NotificationPreferences.page.tsx"));
const PagefilesImport9 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/Providers.layout.tsx"));
const PagefilesImport10 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/Root.page.tsx"));
const PagefilesImport11 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/VirtualDeviceRoot.page.tsx"));
const PagefilesImport12 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/alerts/Alerts.layout.tsx"));
const PagefilesImport13 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/alerts/Alerts.page.tsx"));
const PagefilesImport14 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/autoVPN/AutoVPN.layout.tsx"));
const PagefilesImport15 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/autoVPN/AutoVPNGroup.page.tsx"));
const PagefilesImport16 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/autoVPN/AutoVPNGroups.page.tsx"));
const PagefilesImport17 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/connect/Connect.layout.tsx"));
const PagefilesImport18 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/hub/Hub.layout.tsx"));
const PagefilesImport19 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/hub/HubCards.page.tsx"));
const PagefilesImport20 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/hub/HubList.page.tsx"));
const PagefilesImport21 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/hub/HubMap.page.tsx"));
const PagefilesImport22 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/ControllerTabletJoinInstructions.page.tsx"));
const PagefilesImport23 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/Network.layout.tsx"));
const PagefilesImport24 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/PatchPanelLayout.page.tsx"));
const PagefilesImport25 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/TabletJoinInstructions.page.tsx"));
const PagefilesImport26 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/VirtualNetwork.layout.tsx"));
const PagefilesImport27 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/VirtualNetworkRoot.page.tsx"));
const PagefilesImport28 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/operator/Operator.layout.tsx"));
const PagefilesImport29 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/settings/Settings.layout.tsx"));
const PagefilesImport30 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/tools/Tools.layout.tsx"));
const PagefilesImport31 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/tools/Tools.page.tsx"));
const PagefilesImport32 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/tunnel/Tunnel.layout.tsx"));
const PagefilesImport33 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/connect/locations/ConnectDashboardAllLocations.page.tsx"));
const PagefilesImport34 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/connect/locations/ConnectDashboardLocation.page.tsx"));
const PagefilesImport35 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/design/Design.page.tsx"));
const PagefilesImport36 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/design/DesignLegacy.page.tsx"));
const PagefilesImport37 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/firewall/Firewall.page.tsx"));
const PagefilesImport38 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/firewall/FirewallLegacy.page.tsx"));
const PagefilesImport39 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/Hardware.page.tsx"));
const PagefilesImport40 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/HardwareLegacy.page.tsx"));
const PagefilesImport41 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/insights/Insights.page.tsx"));
const PagefilesImport42 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/insights/InsightsLegacy.page.tsx"));
const PagefilesImport43 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/NetworkWide.page.tsx"));
const PagefilesImport44 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/NetworkWideLegacy.page.tsx"));
const PagefilesImport45 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/onboarding/Onboarding.page.tsx"));
const PagefilesImport46 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/operators/Operators.page.tsx"));
const PagefilesImport47 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/operators/OperatorsLegacy.page.tsx"));
const PagefilesImport48 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/secure_tunnels/SecureTunnels.page.tsx"));
const PagefilesImport49 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/secure_tunnels/SecureTunnelsLegacy.page.tsx"));
const PagefilesImport50 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/wireless/Wireless.page.tsx"));
const PagefilesImport51 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/wireless/WirelessLegacy.page.tsx"));
const PagefilesImport52 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/operator/companies/CompanyList.page.tsx"));
const PagefilesImport53 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/operator/companies/CompanyNetworkList.page.tsx"));
const PagefilesImport54 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/operator/companies/CompanyUsers.page.tsx"));
const PagefilesImport55 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/operator/firmwareBulkUpgrader/AddFirmwareBulkUpgrade.page.tsx"));
const PagefilesImport56 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/operator/firmwareBulkUpgrader/FirmwareBulkUpgrader.layout.tsx"));
const PagefilesImport57 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/operator/firmwareBulkUpgrader/FirmwareBulkUpgradesList.page.tsx"));
const PagefilesImport58 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/operator/nos/NOS.page.tsx"));
const PagefilesImport59 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/operator/security-appliances/SecurityAppplianceList.page.tsx"));
const PagefilesImport60 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/settings/org/Authentication.page.tsx"));
const PagefilesImport61 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/settings/org/Documents.page.tsx"));
const PagefilesImport62 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/settings/org/Users.page.tsx"));
const PagefilesImport63 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/settings/user/Preferences.page.tsx"));
const PagefilesImport64 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/tools/pingtest/PingTest.page.tsx"));
const PagefilesImport65 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/tools/speedtest/SpeedTest.page.tsx"));
const PagefilesImport66 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/tools/speedtest/SpeedTestHistoricalResults.page.tsx"));
const PagefilesImport67 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/tools/support/Support.layout.tsx"));
const PagefilesImport68 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/tools/support/Support.page.tsx"));
const PagefilesImport69 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/design/floor_plans/FloorPlan.page.tsx"));
const PagefilesImport70 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/design/floor_plans/FloorPlans.page.tsx"));
const PagefilesImport71 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/design/floor_plans/FloorPlansLegacy.page.tsx"));
const PagefilesImport72 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/design/rack_elevation/RackElevations.page.tsx"));
const PagefilesImport73 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/design/topology/LegacyTopology.page.tsx"));
const PagefilesImport74 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/design/topology/Topology.page.tsx"));
const PagefilesImport75 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/firewall/dns_security/DNSSecurity.page.tsx"));
const PagefilesImport76 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/firewall/multicast/Multicast.page.tsx"));
const PagefilesImport77 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/firewall/one_to_one_nat/OneToOneNAT.page.tsx"));
const PagefilesImport78 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/firewall/port_forwarding/NATPortForwardingRuleList.page.tsx"));
const PagefilesImport79 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/firewall/port_forwarding/PortForwarding.page.tsx"));
const PagefilesImport80 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/firewall/rate_limiting/RateLimiting.page.tsx"));
const PagefilesImport81 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/firewall/rules/Rules.page.tsx"));
const PagefilesImport82 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/access_points/AccessPoints.page.tsx"));
const PagefilesImport83 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/access_points/LegacyAccessPoints.page.tsx"));
const PagefilesImport84 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/power_distrubution_units/PowerDistributionUnitDetail.page.tsx"));
const PagefilesImport85 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/power_distrubution_units/PowerDistributionUnitList.page.tsx"));
const PagefilesImport86 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/security_appliances/SecurityApplianceDetail.page.tsx"));
const PagefilesImport87 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/security_appliances/SecurityApplianceEmptyState.page.tsx"));
const PagefilesImport88 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/switches/LegacySwitchList.page.tsx"));
const PagefilesImport89 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/switches/SwitchDetail.page.tsx"));
const PagefilesImport90 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/switches/SwitchList.page.tsx"));
const PagefilesImport91 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/insights/clients/ClientConnectionEvents.page.tsx"));
const PagefilesImport92 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/insights/clients/ClientInsights.page.tsx"));
const PagefilesImport93 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/insights/clients/ClientsList.page.tsx"));
const PagefilesImport94 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/insights/clients/LegacyClientDetail.layout.tsx"));
const PagefilesImport95 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/insights/clients/LegacyClientDetail.page.tsx"));
const PagefilesImport96 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/insights/clients/LegacyClientsList.page.tsx"));
const PagefilesImport97 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/insights/host_monitoring/HostMonitoring.page.tsx"));
const PagefilesImport98 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/insights/logs/ControllerLogs.layout.tsx"));
const PagefilesImport99 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/insights/logs/EventLog.page.tsx"));
const PagefilesImport100 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/insights/logs/EventsList.page.tsx"));
const PagefilesImport101 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/insights/logs/NetworkLogs.layout.tsx"));
const PagefilesImport102 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/insights/logs/NetworkMutationsList.page.tsx"));
const PagefilesImport103 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/insights/logs/StatsList.page.tsx"));
const PagefilesImport104 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/insights/network/InsightsNetwork.page.tsx"));
const PagefilesImport105 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/insights/network/LegacyInsights.page.tsx"));
const PagefilesImport106 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/insights/network/Overview.page.tsx"));
const PagefilesImport107 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/captive_portals/CaptivePortal.page.tsx"));
const PagefilesImport108 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/captive_portals/CaptivePortals.page.tsx"));
const PagefilesImport109 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/isps/ISP.page.tsx"));
const PagefilesImport110 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/isps/ISPEvents.page.tsx"));
const PagefilesImport111 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/isps/ISPInsights.page.tsx"));
const PagefilesImport112 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/isps/ISPs.page.tsx"));
const PagefilesImport113 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/isps/InternetAndWireless.page.tsx"));
const PagefilesImport114 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/isps/LegacyISPList.page.tsx"));
const PagefilesImport115 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/radius_profiles/RADIUSProfiles.page.tsx"));
const PagefilesImport116 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/routing/Routing.page.tsx"));
const PagefilesImport117 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/vlans/LegacyVLANList.page.tsx"));
const PagefilesImport118 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/vlans/VLANClientList.page.tsx"));
const PagefilesImport119 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/vlans/VLANDetail.layout.tsx"));
const PagefilesImport120 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/vlans/VLANDetail.page.tsx"));
const PagefilesImport121 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/vlans/VLANDetails.page.tsx"));
const PagefilesImport122 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/vlans/VLANList.page.tsx"));
const PagefilesImport123 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/vlans/VLANSSIDList.page.tsx"));
const PagefilesImport124 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/network_wide/vlans/VLANStaticIPList.page.tsx"));
const PagefilesImport125 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/operators/device_config/DeviceConfig.layout.tsx"));
const PagefilesImport126 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/operators/device_config/DeviceConfigHistory.page.tsx"));
const PagefilesImport127 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/operators/device_config/DeviceConfigHistoryVersion.page.tsx"));
const PagefilesImport128 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/operators/device_config/DeviceConfigOverrides.page.tsx"));
const PagefilesImport129 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/secure_tunnels/ipsec/IPSecTunnels.page.tsx"));
const PagefilesImport130 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/secure_tunnels/vpns/ClientVPN.page.tsx"));
const PagefilesImport131 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/wireless/hotspot20/Hotspot20s.page.tsx"));
const PagefilesImport132 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/wireless/radio_profiles/RadioProfiles.page.tsx"));
const PagefilesImport133 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/wireless/ssids/LegacySSIDList.page.tsx"));
const PagefilesImport134 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/wireless/ssids/SSID.page.tsx"));
const PagefilesImport135 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/wireless/ssids/SSIDDetails.page.tsx"));
const PagefilesImport136 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/wireless/ssids/SSIDs.page.tsx"));
const PagefilesImport137 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/settings/org/integrations/Integrations.page.tsx"));
const PagefilesImport138 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/settings/org/integrations/Receivers.page.tsx"));
const PagefilesImport139 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/settings/org/integrations/Services.page.tsx"));
const PagefilesImport140 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/settings/org/integrations/Webhooks.page.tsx"));
const PagefilesImport141 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/settings/org/networks/General.page.tsx"));
const PagefilesImport142 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/settings/org/networks/ServiceDiscovery.page.tsx"));
const PagefilesImport143 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/settings/org/notifications/Alerts.page.tsx"));
const PagefilesImport144 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/settings/org/notifications/Notifications.page.tsx"));
const PagefilesImport145 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/settings/org/notifications/Reports.page.tsx"));
const PagefilesImport146 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/firewall/dns_security/legacy/DNSSecurity.layout.tsx"));
const PagefilesImport147 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/firewall/dns_security/legacy/DNSSecurity.page.tsx"));
const PagefilesImport148 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/firewall/dns_security/legacy/DNSSecurityCategories.page.tsx"));
const PagefilesImport149 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/firewall/dns_security/legacy/DNSSecurityDomains.page.tsx"));
const PagefilesImport150 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/access_points/bssids/AccessPointsBSSIDs.page.tsx"));
const PagefilesImport151 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/access_points/detail/AccessPoint.page.tsx"));
const PagefilesImport152 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/access_points/detail/AccessPointClientsListDetail.layout.tsx"));
const PagefilesImport153 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/access_points/detail/AccessPointClientsListDetail.page.tsx"));
const PagefilesImport154 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/access_points/detail/LegacyAccessPointDetail.layout.tsx"));
const PagefilesImport155 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/access_points/detail/LegacyAccessPointDetail.page.tsx"));
const PagefilesImport156 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/access_points/insights/AccessPointsInsights.page.tsx"));
const PagefilesImport157 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/access_points/list/AccessPointsList.page.tsx"));
const PagefilesImport158 = React.lazy(() => import("/vercel/path0/apps/dashboard/src/routes/pages/network/secure_tunnels/vpns/legacy/VPNList.page.tsx"));

const routes = [
{path: "*",element: React.createElement(PagefilesImport7)},
{path: "/",element: React.createElement(PagefilesImport10)},
{path: "/app-login",element: React.createElement(PagefilesImport1)},
{path: "/network/:networkUUID",element: React.createElement(PagefilesImport6)},
{path: "/org/:companyName",element: React.createElement(PagefilesImport4)},
{path: "/org/:companyName/controller/:controllerName/insights/clients/:macAddress",element: React.createElement(PagefilesImport95)},
{path: "/org/:companyName/guest",element: React.createElement(PagefilesImport5)},
{element: React.createElement(PagefilesImport9)},
{path: "/org/:companyName/controller/:controllerName/join/:ssid",element: React.createElement(PagefilesImport22)},
{path: "/org/:companyName/network/:networkSlug/join/:ssid",element: React.createElement(PagefilesImport25)},
{path: "/virtual-device/:virtualDeviceUUID",element: React.createElement(PagefilesImport11)},
{children: [{element: React.createElement(PagefilesImport32)},
{path: "/alerts",children: [{index: true,element: React.createElement(PagefilesImport13)},],element: React.createElement(PagefilesImport12)},
{path: "/org/:companyName/command",children: [{index: true,element: React.createElement(PagefilesImport3)},],element: React.createElement(PagefilesImport2)},
{path: "/org/:companyName/support",children: [{index: true,element: React.createElement(PagefilesImport68)},],element: React.createElement(PagefilesImport67)},
{path: "/org/:companyName/auto-vpn",children: [{path: "/org/:companyName/auto-vpn/groups",element: React.createElement(PagefilesImport16)},
{path: "/org/:companyName/auto-vpn/groups/:groupUUID/:activeTab",element: React.createElement(PagefilesImport15)},],element: React.createElement(PagefilesImport14)},
{children: [{path: "/org/:companyName/connect/locations",element: React.createElement(PagefilesImport33)},
{path: "/org/:companyName/connect/locations/:companyLocationSID",element: React.createElement(PagefilesImport34)},],element: React.createElement(PagefilesImport17)},
{path: "/org/:companyName/hub",children: [{path: "/org/:companyName/hub/cards",element: React.createElement(PagefilesImport19)},
{path: "/org/:companyName/hub/list",element: React.createElement(PagefilesImport20)},
{path: "/org/:companyName/hub/map",element: React.createElement(PagefilesImport21)},],element: React.createElement(PagefilesImport18)},
{path: "/org/:companyName/network/:networkSlug/tools",children: [{index: true,element: React.createElement(PagefilesImport31)},
{path: "/org/:companyName/network/:networkSlug/tools/ping-test/:ispName",element: React.createElement(PagefilesImport64)},
{path: "/org/:companyName/network/:networkSlug/tools/speed-test/:ispName",element: React.createElement(PagefilesImport65)},
{path: "/org/:companyName/network/:networkSlug/tools/speed-test/results/:uuid",element: React.createElement(PagefilesImport66)},],element: React.createElement(PagefilesImport30)},
{children: [{path: "/operator/companies",element: React.createElement(PagefilesImport52)},
{path: "/operator/companies/:slug/networks",element: React.createElement(PagefilesImport53)},
{path: "/operator/companies/:slug/users",element: React.createElement(PagefilesImport54)},
{path: "/operator/nos/:tab",element: React.createElement(PagefilesImport58)},
{path: "/operator/security-appliances",element: React.createElement(PagefilesImport59)},
{path: "/operator/firmware-bulk-upgrader",children: [{path: "/operator/firmware-bulk-upgrader/add",element: React.createElement(PagefilesImport55)},
{path: "/operator/firmware-bulk-upgrader/upgrades",element: React.createElement(PagefilesImport57)},],element: React.createElement(PagefilesImport56)},],element: React.createElement(PagefilesImport28)},
{path: "/org/:companyName/settings",children: [{path: "/org/:companyName/settings/org/:networkSlug/networks",element: React.createElement(PagefilesImport141)},
{path: "/org/:companyName/settings/org/:networkSlug/notifications",element: React.createElement(PagefilesImport144)},
{path: "/org/:companyName/settings/org/:networkSlug/notifications/alerts",element: React.createElement(PagefilesImport143)},
{path: "/org/:companyName/settings/org/:networkSlug/notifications/reports",element: React.createElement(PagefilesImport145)},
{path: "/org/:companyName/settings/org/:networkSlug/service-discovery",element: React.createElement(PagefilesImport142)},
{path: "/org/:companyName/settings/org/authentication",element: React.createElement(PagefilesImport60)},
{path: "/org/:companyName/settings/org/documents",element: React.createElement(PagefilesImport61)},
{path: "/org/:companyName/settings/org/integrations",element: React.createElement(PagefilesImport137)},
{path: "/org/:companyName/settings/org/integrations/receivers",element: React.createElement(PagefilesImport138)},
{path: "/org/:companyName/settings/org/integrations/services",element: React.createElement(PagefilesImport139)},
{path: "/org/:companyName/settings/org/integrations/webhooks",element: React.createElement(PagefilesImport140)},
{path: "/org/:companyName/settings/org/users",element: React.createElement(PagefilesImport62)},
{path: "/org/:companyName/settings/user/preferences",element: React.createElement(PagefilesImport63)},],element: React.createElement(PagefilesImport29)},
{path: "/org/:companyName/controller/:controllerName",children: [{path: "/org/:companyName/controller/:controllerName/clients/:macAddress",element: React.createElement(PagefilesImport94)},
{path: "/org/:companyName/controller/:controllerName/design",element: React.createElement(PagefilesImport36)},
{path: "/org/:companyName/controller/:controllerName/design/floor-plans",element: React.createElement(PagefilesImport71)},
{path: "/org/:companyName/controller/:controllerName/design/topology",element: React.createElement(PagefilesImport73)},
{path: "/org/:companyName/controller/:controllerName/firewall",element: React.createElement(PagefilesImport38)},
{path: "/org/:companyName/controller/:controllerName/firewall/dns-security",element: React.createElement(PagefilesImport147)},
{path: "/org/:companyName/controller/:controllerName/firewall/port-forwarding",element: React.createElement(PagefilesImport78)},
{path: "/org/:companyName/controller/:controllerName/hardware",element: React.createElement(PagefilesImport40)},
{path: "/org/:companyName/controller/:controllerName/hardware/access-points",element: React.createElement(PagefilesImport83)},
{path: "/org/:companyName/controller/:controllerName/hardware/switches",element: React.createElement(PagefilesImport88)},
{path: "/org/:companyName/controller/:controllerName/insights",element: React.createElement(PagefilesImport42)},
{path: "/org/:companyName/controller/:controllerName/insights/clients",element: React.createElement(PagefilesImport96)},
{path: "/org/:companyName/controller/:controllerName/internet-wifi",element: React.createElement(PagefilesImport113)},
{path: "/org/:companyName/controller/:controllerName/network-wide",element: React.createElement(PagefilesImport44)},
{path: "/org/:companyName/controller/:controllerName/network-wide/isps",element: React.createElement(PagefilesImport114)},
{path: "/org/:companyName/controller/:controllerName/network-wide/vlans",element: React.createElement(PagefilesImport117)},
{path: "/org/:companyName/controller/:controllerName/operators",element: React.createElement(PagefilesImport47)},
{path: "/org/:companyName/controller/:controllerName/operators/notification-preferences",element: React.createElement(PagefilesImport8)},
{path: "/org/:companyName/controller/:controllerName/secure-tunnels",element: React.createElement(PagefilesImport49)},
{path: "/org/:companyName/controller/:controllerName/wireless",element: React.createElement(PagefilesImport51)},
{index: true,element: React.createElement(PagefilesImport106)},
{path: "/org/:companyName/controller/:controllerName/secure-tunnels/vpn",element: React.createElement(PagefilesImport158)},
{path: "/org/:companyName/controller/:controllerName/wireless/ssids",element: React.createElement(PagefilesImport133)},
{path: "/org/:companyName/controller/:controllerName/devices/:deviceName",children: [{index: true,element: React.createElement(PagefilesImport155)},],element: React.createElement(PagefilesImport154)},
{path: "/org/:companyName/controller/:controllerName/insights/logs",children: [{path: "/org/:companyName/controller/:controllerName/insights/logs/events",element: React.createElement(PagefilesImport100)},],element: React.createElement(PagefilesImport98)},
{children: [{path: "/org/:companyName/controller/:controllerName/dns-security/categories",element: React.createElement(PagefilesImport148)},
{path: "/org/:companyName/controller/:controllerName/dns-security/domains",element: React.createElement(PagefilesImport149)},],element: React.createElement(PagefilesImport146)},
{path: "/org/:companyName/controller/:controllerName/vlans/:vlanName",children: [{path: "/org/:companyName/controller/:controllerName/vlans/:vlanName/clients",element: React.createElement(PagefilesImport118)},
{index: true,element: React.createElement(PagefilesImport120)},
{path: "/org/:companyName/controller/:controllerName/vlans/:vlanName/ssids",element: React.createElement(PagefilesImport123)},
{path: "/org/:companyName/controller/:controllerName/vlans/:vlanName/static",element: React.createElement(PagefilesImport124)},],element: React.createElement(PagefilesImport119)},],element: React.createElement(PagefilesImport23)},
{path: "/org/:companyName/network/:networkSlug",children: [{path: "/org/:companyName/network/:networkSlug/patch-panel-layout",element: React.createElement(PagefilesImport24)},
{index: true,element: React.createElement(PagefilesImport27)},
{path: "/org/:companyName/network/:networkSlug/config/:serialNumber/history/compare",element: React.createElement(PagefilesImport127)},
{path: "/org/:companyName/network/:networkSlug/design",element: React.createElement(PagefilesImport35)},
{path: "/org/:companyName/network/:networkSlug/design/floor-plans",element: React.createElement(PagefilesImport70)},
{path: "/org/:companyName/network/:networkSlug/design/floor-plans/:floorPlanUUID",element: React.createElement(PagefilesImport69)},
{path: "/org/:companyName/network/:networkSlug/design/rack-elevations",element: React.createElement(PagefilesImport72)},
{path: "/org/:companyName/network/:networkSlug/design/topology",element: React.createElement(PagefilesImport74)},
{path: "/org/:companyName/network/:networkSlug/firewall",element: React.createElement(PagefilesImport37)},
{path: "/org/:companyName/network/:networkSlug/firewall/dns-security/:tab",element: React.createElement(PagefilesImport75)},
{path: "/org/:companyName/network/:networkSlug/firewall/multicast",element: React.createElement(PagefilesImport76)},
{path: "/org/:companyName/network/:networkSlug/firewall/one-to-one-nat",element: React.createElement(PagefilesImport77)},
{path: "/org/:companyName/network/:networkSlug/firewall/port-forwarding",element: React.createElement(PagefilesImport79)},
{path: "/org/:companyName/network/:networkSlug/firewall/rate-limiting",element: React.createElement(PagefilesImport80)},
{path: "/org/:companyName/network/:networkSlug/firewall/rules/:tab",element: React.createElement(PagefilesImport81)},
{path: "/org/:companyName/network/:networkSlug/hardware",element: React.createElement(PagefilesImport39)},
{path: "/org/:companyName/network/:networkSlug/hardware/access-points",element: React.createElement(PagefilesImport82)},
{path: "/org/:companyName/network/:networkSlug/hardware/access-points/:uuid/:tab",element: React.createElement(PagefilesImport151)},
{path: "/org/:companyName/network/:networkSlug/hardware/access-points/bssids",element: React.createElement(PagefilesImport150)},
{path: "/org/:companyName/network/:networkSlug/hardware/access-points/insights",element: React.createElement(PagefilesImport156)},
{path: "/org/:companyName/network/:networkSlug/hardware/access-points/list",element: React.createElement(PagefilesImport157)},
{path: "/org/:companyName/network/:networkSlug/hardware/power-distribution-units/:tab",element: React.createElement(PagefilesImport85)},
{path: "/org/:companyName/network/:networkSlug/hardware/power-distribution-units/:uuid/:tab",element: React.createElement(PagefilesImport84)},
{path: "/org/:companyName/network/:networkSlug/hardware/security-appliances",element: React.createElement(PagefilesImport87)},
{path: "/org/:companyName/network/:networkSlug/hardware/security-appliances/:uuid/:tab",element: React.createElement(PagefilesImport86)},
{path: "/org/:companyName/network/:networkSlug/hardware/switches/:tab",element: React.createElement(PagefilesImport90)},
{path: "/org/:companyName/network/:networkSlug/hardware/switches/:uuid/:tab",element: React.createElement(PagefilesImport89)},
{path: "/org/:companyName/network/:networkSlug/insights",element: React.createElement(PagefilesImport41)},
{path: "/org/:companyName/network/:networkSlug/insights/clients",element: React.createElement(PagefilesImport93)},
{path: "/org/:companyName/network/:networkSlug/insights/clients/:macAddress/connection-events",element: React.createElement(PagefilesImport91)},
{path: "/org/:companyName/network/:networkSlug/insights/clients/:macAddress/insights",element: React.createElement(PagefilesImport92)},
{path: "/org/:companyName/network/:networkSlug/insights/host-monitoring",element: React.createElement(PagefilesImport97)},
{path: "/org/:companyName/network/:networkSlug/insights/network",element: React.createElement(PagefilesImport104)},
{path: "/org/:companyName/network/:networkSlug/network-wide",element: React.createElement(PagefilesImport43)},
{path: "/org/:companyName/network/:networkSlug/network-wide/captive-portals",element: React.createElement(PagefilesImport108)},
{path: "/org/:companyName/network/:networkSlug/network-wide/captive-portals/:tab",element: React.createElement(PagefilesImport107)},
{path: "/org/:companyName/network/:networkSlug/network-wide/isps",element: React.createElement(PagefilesImport112)},
{path: "/org/:companyName/network/:networkSlug/network-wide/isps/:isp",element: React.createElement(PagefilesImport109)},
{path: "/org/:companyName/network/:networkSlug/network-wide/isps/:uuid/events",element: React.createElement(PagefilesImport110)},
{path: "/org/:companyName/network/:networkSlug/network-wide/isps/:uuid/insights",element: React.createElement(PagefilesImport111)},
{path: "/org/:companyName/network/:networkSlug/network-wide/radius-profiles",element: React.createElement(PagefilesImport115)},
{path: "/org/:companyName/network/:networkSlug/network-wide/routing/:tab",element: React.createElement(PagefilesImport116)},
{path: "/org/:companyName/network/:networkSlug/network-wide/vlans",element: React.createElement(PagefilesImport122)},
{path: "/org/:companyName/network/:networkSlug/network-wide/vlans/:uuid/:tab",element: React.createElement(PagefilesImport121)},
{path: "/org/:companyName/network/:networkSlug/onboarding",element: React.createElement(PagefilesImport45)},
{path: "/org/:companyName/network/:networkSlug/operators",element: React.createElement(PagefilesImport46)},
{path: "/org/:companyName/network/:networkSlug/operators/network/:tab",element: React.createElement(PagefilesImport105)},
{path: "/org/:companyName/network/:networkSlug/secure-tunnels",element: React.createElement(PagefilesImport48)},
{path: "/org/:companyName/network/:networkSlug/secure-tunnels/ipsec",element: React.createElement(PagefilesImport129)},
{path: "/org/:companyName/network/:networkSlug/secure-tunnels/vpn",element: React.createElement(PagefilesImport130)},
{path: "/org/:companyName/network/:networkSlug/wireless",element: React.createElement(PagefilesImport50)},
{path: "/org/:companyName/network/:networkSlug/wireless/hotspot2.0",element: React.createElement(PagefilesImport131)},
{path: "/org/:companyName/network/:networkSlug/wireless/radio-profiles",element: React.createElement(PagefilesImport132)},
{path: "/org/:companyName/network/:networkSlug/wireless/ssids",element: React.createElement(PagefilesImport136)},
{path: "/org/:companyName/network/:networkSlug/wireless/ssids/:uuid",element: React.createElement(PagefilesImport135)},
{path: "/org/:companyName/network/:networkSlug/wireless/ssids/:uuid/:tab",element: React.createElement(PagefilesImport134)},
{path: "/org/:companyName/network/:networkSlug/devices/:uuid",children: [{index: true,element: React.createElement(PagefilesImport153)},],element: React.createElement(PagefilesImport152)},
{path: "/org/:companyName/network/:networkSlug/operators/config/:serialNumber",children: [{path: "/org/:companyName/network/:networkSlug/operators/config/:serialNumber/history",element: React.createElement(PagefilesImport126)},
{path: "/org/:companyName/network/:networkSlug/operators/config/:serialNumber/overrides",element: React.createElement(PagefilesImport128)},],element: React.createElement(PagefilesImport125)},
{path: "/org/:companyName/network/:networkSlug/insights/logs",children: [{index: true,element: React.createElement(PagefilesImport99)},
{path: "/org/:companyName/network/:networkSlug/insights/logs/mutations",element: React.createElement(PagefilesImport102)},
{path: "/org/:companyName/network/:networkSlug/insights/logs/stats",element: React.createElement(PagefilesImport103)},],element: React.createElement(PagefilesImport101)},],element: React.createElement(PagefilesImport26)},],element: React.createElement(PagefilesImport0)},
];

export default routes;