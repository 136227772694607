import type { LoaderOptions } from '@googlemaps/js-api-loader';
import { Loader } from '@googlemaps/js-api-loader';

import { logError } from './utils/logError';

export function init(additionalOptions?: Omit<LoaderOptions, 'apiKey'>) {
  try {
    const loader = new Loader({
      apiKey: import.meta.env.GOOGLE_MAPS_API_KEY,
      version: '3.55.8',
      ...additionalOptions,
    });
    loader.importLibrary('maps');
  } catch (e) {
    logError(e);
  }
}
