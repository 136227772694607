import { RoleName } from '../gql/graphql';

/**
 * A constant value 'all' for glob-ing role assignments across a company or companies.
 * @description This can be used to retrieve all roles when used within specific context.
 * - If the entity 'companySlug' is specified, and `networkUUID` is set to 'All', it returns all roles for a user in a company.
 * - If 'All' is used for both 'companySlug' and 'networkUUID', it returns all roles for all companies and networks.
 * @example
 * GetRolesForCompany({ companySlug: 'Company', networkUUID: All }); // returns all roles for a user in a 'Company'.
 * GetRolesForCompany({ companySlug: All, networkUUID: All }); // returns all roles for a user in all companies and networks.
 * @export
 */
export const All = 'all' as const;

/**
 * The value to use for checking a role assignment that's in the context of every network, potentially scoped by a company.
 * @description This can be used to retrieve all roles for a user that apply to ALL networks.
 * @example
 * GetRolesForCompany({ companySlug: 'Company', networkUUID: GlobalNetwork }); // returns company admin role if assigned.
 * @export
 */
export const GlobalNetwork = null;
export type GlobalNetworkType = typeof GlobalNetwork;

/**
 * The value to use for checking a role assignment that's in the context of every company.
 * @description This can be used to retrieve all roles for a user that apply to ALL companies _AND_ ALL networks.
 * @example
 * GetRolesForCompany({ companySlug: GlobalCompany, networkUUID: GlobalNetwork }); // returns operator role if assigned.
 * @export
 */
export const GlobalCompany = null;
export type GlobalCompanyType = typeof GlobalCompany;

export type Globals = GlobalNetworkType | GlobalCompanyType;

export function isGlobal(val: AllOrGlobal | unknown): val is Globals {
  return val === GlobalNetwork || val === GlobalCompany;
}

export function isAll(val: AllOrGlobal | unknown): val is typeof All {
  return val === All;
}

type Expand<T> = T extends infer O ? { [K in keyof O]: O[K] } : never;

type Raw<T> = Omit<T, '__type__'>;

export type AllOrGlobal = typeof All | Globals;

export type AllGlobalOrSpecific = AllOrGlobal | string;

export const hasRoleParamsType = 'HasRoleParams' as const;

type HasRoleParamsType = typeof hasRoleParamsType;

export type HasRoleParams = {
  companySlug?: AllGlobalOrSpecific;
  networkUUID?: AllGlobalOrSpecific;
  roleName: RoleName;
  readonly __type__: HasRoleParamsType;
};

export type HasRoleInput = Expand<Raw<HasRoleParams>>;

export function HasRole<T extends HasRoleInput>(args: T): HasRoleParams {
  return {
    companySlug: GlobalCompany,
    networkUUID: GlobalNetwork,
    ...args,
    __type__: hasRoleParamsType,
  };
}

export const HasOperatorRole = HasRole({ roleName: RoleName.Operator });

export function HasCompanyAdminRole(companySlug: string): HasRoleParams {
  return HasRole({ roleName: RoleName.CompanyGlobalAdmin, companySlug });
}

export function HasNetworkAdminRole({
  companySlug,
  networkUUID,
}: {
  companySlug: string;
  networkUUID: string;
}): HasRoleParams {
  return HasRole({ roleName: RoleName.CompanyNetworkAdmin, companySlug, networkUUID });
}

export const getRolesMultiNetworkParamsType = 'GetRolesMultiNetworkParams' as const;
type GetRolesMultiNetworkParamsType = typeof getRolesMultiNetworkParamsType;
export type GetRolesMultiNetworkParams = {
  companySlug: AllGlobalOrSpecific;
  networkUUIDs: AllOrGlobal | string[];
  __type__: GetRolesMultiNetworkParamsType;
};

export function GetRolesAcrossNetworks<T extends Expand<Raw<GetRolesMultiNetworkParams>>>(
  args: T,
): GetRolesMultiNetworkParams {
  return {
    ...args,
    __type__: getRolesMultiNetworkParamsType,
  };
}

export const getRolesForCompanyParamsType = 'GetRolesForCompanyParams' as const;
type GetRolesForCompanyParamsType = typeof getRolesForCompanyParamsType;
export type GetRolesForCompanyParams = {
  companySlug: string;
  networkUUID?: AllGlobalOrSpecific;
  roleName?: typeof All | RoleName;
  __type__: GetRolesForCompanyParamsType;
};

export function GetRolesForCompany<T extends Expand<Raw<GetRolesForCompanyParams>>>(
  args: T,
): GetRolesForCompanyParams {
  return {
    ...args,
    __type__: getRolesForCompanyParamsType,
  };
}

export type GetRolesParams = HasRoleParams | GetRolesMultiNetworkParams | GetRolesForCompanyParams;
