import type { DisplayableError } from '@meterup/common';
import { Alert, Body, Drawer, DrawerContent, DrawerHeader, Heading, Link } from '@meterup/atto';
import { isDisplayableError, NoConnectionError } from '@meterup/common';

import type { ErrorFallbackProps } from './utils';
import { SUPPORT_URL } from '../../constants';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { styled } from '../../stitches';
import { Box } from '../Box';
import { GlobalMaintenanceScreen } from '../GlobalMaintenanceKillswitch';
import { DevelopmentErrorFallback } from './DevelopmentErrorFallback';

const ErrorFallbackContainer = styled('div', {
  vStack: '$16',
  alignItems: 'stretch',
  width: '100vw',
  height: '100vh',
  padding: '$20',
});
ErrorFallbackContainer.displayName = 'ErrorFallbackContainer';

const ErrorFallbackPosition = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  alignSelf: 'center',
  width: '100%',
  height: '100%',
  maxWidth: '500px',

  '@maxSm': {
    paddingTop: '40%',
  },

  '@sm': {
    paddingTop: '12%',
  },
});
ErrorFallbackPosition.displayName = 'ErrorFallbackPosition';

export function RefreshOrContactSupportBoilerplate() {
  return (
    <>
      <Body>
        <p>
          Our team has been notified and is working to resolve the issue as soon as possible. Please
          try again, if the issue persists please <Link href={SUPPORT_URL}>contact support</Link>.
        </p>
      </Body>
      <Body>
        <p>
          Your local network and internet connection (including Wi-Fi) are not affected and will
          continue functioning normally.
        </p>
      </Body>
    </>
  );
}

export function ErrorFallback(props: ErrorFallbackProps) {
  const { error } = props;
  const title = isDisplayableError(error) ? error.displayTitle : 'Something went wrong';
  const message = isDisplayableError(error) ? (
    error.displayMessage
  ) : (
    <>
      An unexpected error occurred. <RefreshOrContactSupportBoilerplate />
    </>
  );

  return import.meta.env.NODE_ENV === 'development' ? (
    <DevelopmentErrorFallback {...props} />
  ) : (
    <ErrorFallbackContainer>
      <ErrorFallbackPosition>
        <Alert variant="neutral" heading={title} copy={message} />
      </ErrorFallbackPosition>
    </ErrorFallbackContainer>
  );
}

function UnexpectedErrorFallbackDrawer({
  componentStack,
  error,
  eventId,
  resetError,
}: ErrorFallbackProps) {
  return (
    <Drawer>
      <DrawerHeader heading="Unexpected error" onClose={useCloseDrawerCallback()} />
      <DrawerContent gutter="all">
        {import.meta.env.NODE_ENV === 'development' ? (
          <DevelopmentErrorFallback
            error={error}
            componentStack={componentStack}
            resetError={resetError}
            eventId={eventId}
          />
        ) : (
          <ErrorFallbackContainer>
            <Alert
              variant="neutral"
              copy={
                <>
                  An unexpected error has occurred. Please try refreshing the page. If the issue
                  persists, please <Link href="https://www.meter.com/support">contact support</Link>
                  .
                </>
              }
            />
          </ErrorFallbackContainer>
        )}
      </DrawerContent>
    </Drawer>
  );
}

function DisplayableErrorFallbackDrawer({
  componentStack,
  error,
  eventId,
  resetError,
}: ErrorFallbackProps & { error: DisplayableError }) {
  return (
    <Drawer>
      <DrawerHeader heading={error.displayTitle} onClose={useCloseDrawerCallback()} />
      <DrawerContent gutter="all">
        {import.meta.env.NODE_ENV === 'development' ? (
          <DevelopmentErrorFallback
            error={error}
            componentStack={componentStack}
            resetError={resetError}
            eventId={eventId}
          />
        ) : (
          <Alert variant="neutral" copy={error.displayMessage} />
        )}
      </DrawerContent>
    </Drawer>
  );
}

export function ErrorFallbackDrawer(props: ErrorFallbackProps) {
  const { error } = props;
  return isDisplayableError(error) ? (
    <DisplayableErrorFallbackDrawer {...props} error={error} />
  ) : (
    <UnexpectedErrorFallbackDrawer {...props} />
  );
}

function FooterBoilerplate() {
  return (
    <Body>
      Meter, Inc. • <Link href="https://www.meter.com/support">Support & FAQs</Link>
    </Body>
  );
}

export function FatalErrorFallback(props: ErrorFallbackProps) {
  const { error } = props;
  if (import.meta.env.NODE_ENV === 'development') {
    return <DevelopmentErrorFallback {...props} />;
  }

  if (error instanceof NoConnectionError) {
    return <GlobalMaintenanceScreen />;
  }

  const title = isDisplayableError(error) ? error.displayTitle : 'Something went wrong';
  const message = isDisplayableError(error)
    ? error.displayMessage
    : 'An unexpected error occurred.';

  return (
    <Box
      css={{
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box css={{ vStack: '$32', maxWidth: 400, alignItems: 'stretch' }}>
        <Box css={{ vStack: '$20', alignItems: 'flex-start' }}>
          <Box css={{ vStack: '$4', alignItems: 'flex-start' }}>
            <Heading>{title}</Heading>
          </Box>
          <Body>{message}</Body>
          <RefreshOrContactSupportBoilerplate />
        </Box>
        <FooterBoilerplate />
      </Box>
    </Box>
  );
}
