import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';

import { GlobalCompany } from '../core/typesHelpers';
import { graphql } from '../gql';

const useRolesQuery = graphql(`
  query UseRolesQuery($companySlug: String) {
    roles(input: { companySlug: $companySlug }) {
      __typename
      companySlug
      name
      networkUUID
    }
  }
`);

type UseRolesParams = {
  companySlug: string;
  networkUUID?: string;
};

/**
 * @deprecated Use `usePermissions` instead. Do not check the user roles directly.
 */
export function useRoles(params?: UseRolesParams) {
  const companySlug = useMemo(() => params?.companySlug ?? GlobalCompany, [params?.companySlug]);
  return useGraphQL(useRolesQuery, { companySlug });
}
