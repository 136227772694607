import { useMemo } from 'react';

import { HighAvailabilityStatus, VirtualDeviceType } from '../gql/graphql';
import { useCurrentCompanyFromPathOrNull } from './useCurrentCompanyFromPath';
import { type Network, useNetworksForCompany } from './useNetworksForCompany';

export function getActiveControllerForNetwork<N extends Pick<Network, 'virtualDevices'>>(
  network: N | null,
): N['virtualDevices'][number] | null {
  const controllers =
    network?.virtualDevices.filter(
      (
        device,
      ): device is typeof device & {
        __typename: 'ControllerVirtualDevice';
        hardwareDevice: NonNullable<(typeof device)['hardwareDevice']>;
      } =>
        device.__typename === 'ControllerVirtualDevice' &&
        device?.deviceType === VirtualDeviceType.Controller,
    ) ?? [];

  return (
    // Prioritize the active HA controller if HA configured
    controllers.find(
      (device) => device?.highAvailability?.status === HighAvailabilityStatus.Active,
    ) ??
    // Try to find the first active controller
    controllers.find((device) => !!device?.hardwareDevice?.isActive) ??
    // Fall back to just the first one in case none active
    controllers[0] ??
    null
  );
}

export function useActiveControllerForNetwork<N extends Pick<Network, 'virtualDevices'>>(
  network: N | null,
): N['virtualDevices'][number] | null {
  return useMemo(() => getActiveControllerForNetwork(network), [network]);
}

export function useNetworkAndActiveControllerForUUID(networkUUID: string | null) {
  const company = useCurrentCompanyFromPathOrNull();
  const networks = useNetworksForCompany(company?.company_slug ?? null);
  if (!networkUUID) return null;

  const network = networks.find((n) => n.UUID === networkUUID);
  if (!network) return null;

  const controller = getActiveControllerForNetwork(network);

  return {
    network,
    controllerSerialNumber: controller?.hardwareDevice?.serialNumber ?? null,
  };
}

export function useNetworkAndActiveControllerForSlug(networkSlug: string | null) {
  const company = useCurrentCompanyFromPathOrNull();
  const networks = useNetworksForCompany(company?.company_slug ?? null);
  if (!networkSlug) return null;

  const network = networks.find((n) => n.slug === networkSlug);
  if (!network) return null;

  const controller = getActiveControllerForNetwork(network);

  return {
    network,
    controllerSerialNumber: controller?.hardwareDevice?.serialNumber ?? null,
  };
}
