import type { HasChildren } from '@meterup/atto';
import {
  backgrounds,
  Button,
  ControlGroup,
  darkThemeSelector,
  selectors,
  shadows,
} from '@meterup/atto';
import * as d3 from 'd3';
import { isEqual } from 'lodash-es';
import React, { useEffect, useRef, useState } from 'react';

import { colors, styled } from '../stitches';

const MIN_ZOOM = 0.25;
const MAX_ZOOM = 100;

const Container = styled('div', {
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
  background: backgrounds.dottedLight,
  backgroundColor: colors.bgApplicationLight,
  borderRadius: '$10',
  outline: 'none',

  '&:before': {
    content: '',
    position: 'absolute',
    inset: 0,
    zIndex: 1,
    pointerEvents: 'none',
  },

  [darkThemeSelector]: {
    background: backgrounds.dottedDark,
    backgroundColor: colors.bgApplicationDark,
  },

  [selectors.focus]: {
    '&:before': {
      boxShadow: shadows.focusRingLight,

      [darkThemeSelector]: {
        boxShadow: shadows.focusRingDark,
      },
    },
  },
});

const PanningContent = styled('div', {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '$12',
  transformOrigin: '0 0',
});

const ControlsRegion = styled(ControlGroup, {
  position: 'absolute',
  bottom: '$16',
  right: '$16',
  padding: '$8 $10',
  background: colors.bgApplicationLight,
  boxShadow: shadows.overlayLight,
  borderRadius: '$12',

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
    boxShadow: shadows.overlayDark,
  },
});

export function PanAndZoomRegion({ children }: HasChildren) {
  const [transform, setTransform] = useState(d3.zoomIdentity);
  const zoomContainerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const zoomBehaviorRef = useRef<d3.ZoomBehavior<HTMLDivElement, any> | null>(null);
  const zoomSelectionRef = useRef<d3.Selection<HTMLDivElement, unknown, null, undefined> | null>(
    null,
  );

  useEffect(() => {
    if (zoomContainerRef.current) {
      const zoomBehavior = d3
        .zoom<HTMLDivElement, any>()
        .scaleExtent([MIN_ZOOM, MAX_ZOOM])
        .filter(
          (e) =>
            e.touches?.length > 1 ||
            containerRef.current === document.activeElement ||
            (e.type === 'mousedown' && (containerRef.current?.contains(e.currentTarget) ?? false)),
        );

      zoomBehaviorRef.current = zoomBehavior;
      zoomSelectionRef.current = d3.select(zoomContainerRef.current);

      zoomBehavior.on('zoom', (e: d3.D3ZoomEvent<any, any>) => setTransform(e.transform));
      zoomBehavior(zoomSelectionRef.current);

      return () => {
        zoomBehavior.on('zoom', null);
      };
    }

    return () => {};
  }, []);

  const canZoomIn = transform.k < MAX_ZOOM;
  const canZoomOut = transform.k > MIN_ZOOM;
  const canReset = !isEqual(transform, d3.zoomIdentity);

  const zoomIn = () => {
    if (zoomBehaviorRef.current && zoomSelectionRef.current) {
      zoomBehaviorRef.current.scaleBy(zoomSelectionRef.current?.transition(), 1.5);
    }
  };

  const zoomOut = () => {
    if (zoomBehaviorRef.current && zoomSelectionRef.current) {
      zoomBehaviorRef.current.scaleBy(zoomSelectionRef.current?.transition(), 0.5);
    }
  };

  const resetZoom = () => {
    if (zoomBehaviorRef.current && zoomSelectionRef.current) {
      zoomBehaviorRef.current?.transform(zoomSelectionRef.current?.transition(), d3.zoomIdentity);
    }
  };

  return (
    <Container ref={containerRef} tabIndex={-1}>
      <div ref={zoomContainerRef} style={{ width: '100%', height: '100%' }}>
        <PanningContent
          style={{
            transform: `translate(${transform.x}px,${transform.y}px) scale(${transform.k})`,
          }}
        >
          {children}
        </PanningContent>
      </div>
      <ControlsRegion size="medium">
        <Button
          variant="secondary"
          arrangement="hidden-label"
          icon="plus"
          onClick={zoomIn}
          disabled={!canZoomIn}
        >
          Zoom in
        </Button>
        <Button
          variant="secondary"
          arrangement="hidden-label"
          icon="minus"
          onClick={zoomOut}
          disabled={!canZoomOut}
        >
          Zoom out
        </Button>
        <Button
          variant="secondary"
          arrangement="hidden-label"
          icon="arrows-rotate"
          onClick={resetZoom}
          disabled={!canReset}
        >
          Reset
        </Button>
      </ControlsRegion>
    </Container>
  );
}
