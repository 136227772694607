import { Button, EmptyState, Logo } from '@meterup/atto';
import React from 'react';

import { useLogoutHandler } from '../../hooks/useLogoutHandler';
import { styled } from '../../stitches';

export const Meta = () => ({
  path: '/org/:companyName/guest',
  layout: null,
});

const LogoContainer = styled('div', { marginBottom: '$16' });

export default function GuestPage() {
  const logout = useLogoutHandler();

  return (
    <EmptyState
      heading={
        <LogoContainer>
          <Logo size={40} type="word" />
        </LogoContainer>
      }
      action={
        <Button
          variant="secondary"
          arrangement="leading-icon"
          icon="power"
          size="medium"
          onClick={() => logout()}
        >
          Logout
        </Button>
      }
    />
  );
}
