import { APIProxyBaseURL } from '@meterup/common';
import { GraphQLClient } from 'graphql-request';

export const GraphQLURL = `${APIProxyBaseURL}/v2/graphql`;

export const graphqlClient = new GraphQLClient(GraphQLURL, {
  credentials: 'include',
  // NOTE: To get the error AND data fields using this option the `rawRequest` method MUST be used.
  //        Currently, we use the `request` method, so this will not have an effect. We will need to
  //        figure out how to use `rawRequest` with react-query to return both the error and data fields.
  errorPolicy: 'all',
});
