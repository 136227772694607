import { useIsOperator } from '@meterup/authorization';
import { OPERATOR_ACTIONS_GROUP_NAME, useCommand, useRegisterCommands } from '@meterup/command';
import { useGraphQL } from '@meterup/graphql';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useDebounce } from 'use-debounce';

import { paths } from '../../constants';
import { graphql } from '../../gql';
import { makeLink } from '../../utils/main_and_drawer_navigation';

const networkQuery = graphql(`
  query NetworkForNavigation($networkUUID: UUID!) {
    network(UUID: $networkUUID) {
      UUID
      slug
      label

      companySlug
    }
  }
`);

export function useNavigateToNetworkCommand() {
  const isOperator = useIsOperator();
  const { state } = useCommand();
  const navigate = useNavigate();

  const [searchQuery] = useDebounce(state?.ui?.search ?? '', 250);

  const network = useGraphQL(
    networkQuery,
    { networkUUID: searchQuery },
    {
      suspense: false,
      useErrorBoundary: false,
      enabled: !!searchQuery,
    },
  ).data?.network;

  const { nodeFactory } = state;

  const nodes = useMemo(() => {
    if (isOperator && network?.companySlug) {
      return [
        nodeFactory.action({
          id: 'navigate-to-network',
          group: OPERATOR_ACTIONS_GROUP_NAME,
          display: `Navigate to network ${network.label} (${network.UUID})`,
          label: `Navigate to network ${network.label} (${network.UUID})`,
          icon: 'network-wide',
          internal: true,
          async onSelect() {
            if (!network.companySlug) return;

            navigate(
              makeLink(paths.pages.IndividualNetworkRootPage, {
                companyName: network.companySlug,
                networkSlug: network.slug,
              }),
            );
          },
        }),
      ];
    }

    return [];
  }, [isOperator, network, navigate, nodeFactory]);

  useRegisterCommands(nodes, [network, navigate, isOperator]);
}
