import { checkDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { useMemo } from 'react';

import { paths } from '../constants';
import { Nav } from '../nav';
import { useActiveControllerForNetwork } from './useActiveControllerForNetwork';
import { useControllerDataFromPathOrNull } from './useControllerDataFromPath';
import { useCurrentCompanyFromPathOrNull } from './useCurrentCompanyFromPath';
import { type Network, useNetworksForCompany } from './useNetworksForCompany';

export type { Network };

export function useCompanyAndNetworkSlugsFromPath() {
  const params = Nav.useRegionParams('root', paths.pages.IndividualNetworkRootPage);
  if (params?.networkSlug && params?.companyName) {
    return {
      networkSlug: params.networkSlug,
      companySlug: params.companyName,
    };
  }
  return null;
}

export function useNetworkSlugFromPathOrNull() {
  const slugs = useCompanyAndNetworkSlugsFromPath();
  return slugs?.networkSlug ?? null;
}

export function useNetworkSlugFromPath() {
  return checkDefinedOrThrow(
    useNetworkSlugFromPathOrNull(),
    new ResourceNotFoundError('Could not find network slug in path'),
  );
}

export function useNetworkUUIDFromPathOrNull() {
  const params = Nav.useRegionParams('root', paths.pages.NetworkRootPage);
  return params?.networkUUID ?? null;
}

/**
 * Works with both controller and network based URLs.
 */
export function useNetworkOrNull(): Network | null {
  const company = useCurrentCompanyFromPathOrNull();
  const networkSlug = useNetworkSlugFromPathOrNull();

  const networks = useNetworksForCompany(company?.company_slug);
  const controllerData = useControllerDataFromPathOrNull();

  return useMemo(() => {
    if (networkSlug) return networks.find((network) => network.slug === networkSlug) ?? null;

    if (controllerData?.currentController?.network_uuid) {
      const found = networks.find(
        (network) => network.UUID === controllerData?.currentController?.network_uuid,
      );

      if (found) return found;
    }

    if (controllerData?.controllerNameFromPath) {
      const found = networks.find((network) =>
        network.virtualDevices?.some(
          (vd) => vd.hardwareDevice?.serialNumber === controllerData.controllerNameFromPath,
        ),
      );
      if (found) return found;
    }

    return null;
  }, [
    networks,
    networkSlug,
    controllerData?.controllerNameFromPath,
    controllerData?.currentController?.network_uuid,
  ]);
}

/**
 * Works with both controller and network based URLs.
 */
export function useNetwork(): Network {
  return checkDefinedOrThrow(
    useNetworkOrNull(),
    new ResourceNotFoundError('Could not find network'),
  );
}

/**
 * Works with both controller and network based URLs.
 */
export function useNetworkUUID(): string | null {
  const network = useNetworkOrNull();
  return network?.UUID ?? null;
}

/**
 * @deprecated Use `useNetwork` instead.
 */
export function useNetworkFromPath() {
  const network = useNetwork();
  const controller = useActiveControllerForNetwork(network);
  return { network, controllerSerialNumber: controller?.hardwareDevice?.serialNumber ?? null };
}
